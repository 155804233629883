import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState, useCallback } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import ModalLayout from 'components/layouts/ModalLayout';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import Tag from 'components/atom/tag';
import { GLabelAtom } from 'components/atom/label';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import styled from '@emotion/styled';
import InputField from 'components/atom/input';
import { GCodeSelect } from 'components/selects/GCodeSelect';
import { CommonCode } from 'models/admin/CommonCode';
import FileDownloadVars from 'components/vars/common/FileDownloadVars';
import FileUploadVars from 'components/vars/common/FileUploadVars';
import { useAccountRegist } from '../hooks/useAccountRegist';
import { getJwtCommonCodes } from 'apis/vars/common/Common';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import useFrgnRegist from './hooks/useFrgnRegist';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { ForeignAccountVO } from 'models/vars/account/ForeignAccountVO';
import { getForeignAccount, modifyForeignAccount } from 'apis/vars/account/ForeignAccount';
import { GridBox, GridItem } from 'components/organism/GridBox';
import {
  getAccnValid0237,
  getAccnValidG0237,
  getAccnValidInfo,
  getSapIfStdInfo,
  getSapIfStdInfoG,
} from 'apis/vars/account/CommonAccount';
import { AccountValidVO } from 'models/vars/account/AccountValidVO';
import GRadioGroup from 'components/radios/GRadioGroup';
import ForeignCurrencyBank from './FrgnCurrencyBankModal';
import { GLabelAtomVars } from 'components/vars/common/GLabelAtomVars';
import Download from 'components/asset/images/Download.svg';
import usePifPrint from '../hooks/usePifPrint';

type DmsChgFrgnAcntModalProps = {
  open: boolean;
  close: (param?: boolean) => void;
  save: (param?: boolean) => void;
  vendorInfo?: any;
  frgnAccount?: any;
  stdAccnCds?: any;
  node: any;
  isKrw: boolean;
};

interface AccnChgVO extends ForeignAccountVO {
  currCd?: string; //미사용
  ibanYn?: string;
  isDpstNmCert?: string; // 화면에서 사용
  isDpstNmCertRslt?: string;
}

interface AccnFiles {
  fileId1?: string;
  fileId2?: string;
  fileId3?: string;
  // fileId4?: string;
  fileId5?: string;
}

const DmsChgFrgnAcntModal = (props: DmsChgFrgnAcntModalProps) => {
  const [bankNumberModalOpen, setBankNumberModalOpen] = useState<boolean>(false);
  const [bankNoTpCdList, setBankNoTpCdList] = useState<CommonCode[]>([]); //BANK_NO_TP_CD
  const [bankLoctCtryCdList, setBankLoctCtryCdList] = useState<CommonCode[]>([]); //BANK_LOCT_CTRY_CD

  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { getTagStatus } = useAccountRegist({});
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();

  const [curCdList, setCurCdList] = useState<CommonCode[]>([]);
  const [dtlFrgnAccount, setDtlFrgnAccount] = useState<ForeignAccountVO>();
  const [wktDivsCdList, setWktDivsCdList] = useState<CommonCode[]>([]);

  const { openLoading } = useLoading();
  const { regSchema, regSchemaKrw, regSchemaDo } = useFrgnRegist();
  const files = {} as AccnFiles;

  const [dmstFrgDivsCd, setDmstFrgDivsCd] = useState<string>(props.vendorInfo.dmstFrgDivsCd);

  const [chgAccnInfo, setChgAccnInfo] = useState<AccnChgVO>({
    ...dtlFrgnAccount,
    ...props.vendorInfo,
    isDpstNmCert: props.isKrw ? 'Y' : 'N',
    txCd: props.vendorInfo.copRegNo,
    copList: [props.vendorInfo.soprMgrCopCd],
    currCd: props.isKrw ? 'KRW' : dtlFrgnAccount?.currCd ?? 'USD',
    accnNoCertYn: 'N',
    addAtrValCtn06: 'N',
    ibanYn: props.frgnAccount.ibanCd ? 'IBAN' : 'Normal',
    wktDivsCd: dtlFrgnAccount?.addAtrValCtn03 ?? 'U',
    bankKeyCd: '', //뱅크키 초기화
    isDpstNmCertRslt: 'N',
    bankCd: '', //0404 뱅크코드 초기화
  } as AccnChgVO);

  const [certFlag, setCertFlag] = useState<boolean>(false);

  const fileDropzoneRef1 = useRef<any>();
  const fileDropzoneRef2 = useRef<any>();
  const fileDropzoneRef3 = useRef<any>();
  // const fileDropzoneRef4 = useRef<any>();
  const fileDropzoneRef5 = useRef<any>();

  /**
   * 은행소재지
   * @param e
   */
  const handleChangeBankLoctCtryCd = (e) => {
    setChgAccnInfo((prev) => {
      return {
        ...prev,
        bankLoctCtryCd: e.target.value,
        bankCtryCd: e.target.value,
        swftCd: '',
      };
    });
  };

  /**
   * 계좌 추가정보
   * @param e
   */
  const handleChangeBankNoTpCd = (e) => {
    setChgAccnInfo((prev) => {
      return {
        ...prev,
        bankNoTpCd: e.target.value,
        bankNo: e.target.value == '9' || e.target.value == '' ? '' : prev.bankNo,
      };
    });
  };

  const handleChangeIbanYn = (e) => {
    const selVal = e.target.value;
    setChgAccnInfo((prev) => {
      return {
        ...prev,
        ibanYn: selVal,
        ibanCd: selVal == 'IBAN' ? prev.ibanCd : '',
        bankNoTpCd: selVal == 'IBAN' ? '2' : '', //  sortCode
        encAccnNo: selVal == 'IBAN' ? '' : prev.encAccnNo,
        addAtrValCtn06: 'N',
        isDpstNmCertRslt: 'N',
      };
    });
  };

  const onSearchBankClick = () => {
    setBankNumberModalOpen(true);
  };

  const handleClose = (result?: boolean) => {
    props.close(result);
  };

  const handleValidAccnClick = async () => {
    if (!chgAccnInfo.bankCd) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00729', '__SWIFT/BIC를 입력하세요.'),
      });
      return;
    }

    if (!chgAccnInfo.encAccnNo) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00448', '__계좌번호를 입력하세요.'),
      });

      return;
    }

    if (!chgAccnInfo.dpstNm) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00449', '__예금주를 입력하세요.'),
      });
      return;
    }

    if (!chgAccnInfo.currCd) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00450', '__통화를 입력하세요.'),
      });
      return;
    }

    const accnValidParam = {
      ZBNKY: chgAccnInfo.bankCd,
      ZBNKN: chgAccnInfo.encAccnNo,
      ZNME1: chgAccnInfo.dpstNm,
      WAERS: chgAccnInfo.currCd,
    } as AccountValidVO;

    // if (!props.vendorInfo.encRgno && !props.vendorInfo.bzno) {
    //   openMessageBar('warning', t('com.label.00441', '__인증에 실패하였습니다 .'));
    //   setChgAccnInfo((prev) => {
    //     return {
    //       ...prev,
    //       isDpstNmCertRslt: 'Y',
    //     };
    //   });
    //   return;
    // }

    openLoading(true);
    await getAccnValidInfo(accnValidParam)
      .then((resp) => {
        openLoading(false);
        if (resp.result == 'S') {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              addAtrValCtn06: 'Y', //예금주 확인 완료 여부
              addAtrValCtn08: resp.result, //예금주 인증처리 결과코드
              addAtrValCtn09: resp.e_ZNME1, //예금주 인증처리 결과내용
              addDpstNm: resp.e_ZNME1, //예금주 확인 필드
              isDpstNmCertRslt: 'Y',
            };
          });
          openMessageBar('success', t('com.label.00040', '__인증에 성공하였습니다.'));
        } else {
          if (!props.isKrw) {
            setChgAccnInfo((prev) => {
              return {
                ...prev,
                isDpstNmCertRslt: 'Y',
                addAtrValCtn08: resp.result ?? '', //예금주 인증처리 결과코드
                addAtrValCtn09: resp.e_ZNME1 ?? '', //예금주 인증처리 결과내용
                addDpstNm: resp.e_ZNME1 ?? '', //예금주 확인 필드

                // addAtrValCtn08: 'S', //테스트용도 24.06.08
                // addAtrValCtn09: 'tester', //테스트용도 24.06.08
                // addDpstNm: 'tester', //테스트용도 24.06.08
                // isDpstNmCertRslt: 'Y', //테스트용도 24.06.08
              };
            });
          }
          alert(resp.error_INFO);
        }
      })
      .catch((e) => {
        console.error('error', e);
        openLoading(false);
      });
  };

  /**
   *  중복계좌 번호 체크 로직
   */
  const validDuplAccn = () => {
    const accounts = props.node.parent.allLeafChildren.filter(
      (o) => o.rowIndex != props.node.rowIndex
    );

    let duplAccn;
    if (chgAccnInfo.ibanYn == 'Normal') {
      duplAccn = accounts.filter((o) => o.encAccnNo == chgAccnInfo.encAccnNo?.trim());
    } else {
      duplAccn = accounts.filter((o) => o.ibanCd == chgAccnInfo.ibanCd?.trim());
    }

    if (duplAccn.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 선택한 통화와 동일한 통화가 진행중 상태에 있는지 여부 체크
   */
  const validDuplCurr = () => {
    //0327 주석제거
    //0329 수정
    // 추가계좌 가능
    // 0406 추가 계좌 불가하도록 수정

    // 선택한 행 이외의 데이터
    const accounts = props.node.parent.allLeafChildren.filter(
      (o) => o.rowIndex != props.node.rowIndex
    );

    // 동일한 통화 데이터 중, 상태가 임시저장이거나 진행중인 대상이 있는지.
    const duplAccn = accounts.filter(
      (o) =>
        o.data.currCd == chgAccnInfo.currCd &&
        (o.data.accnRegProgStatCd == 'P' || o.data.accnRegProgStatCd == 'T')
    );

    if (duplAccn.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnSave = () => {
    // if (validDuplAccn()) {
    //   openCommonModal({
    //     modalType: 'alert',
    //     content: t('com.label.00451', '__중복된 계좌를 등록할 수 없습니다.'),
    //   });
    //   return;
    // }
    if (!emailValid()) return;

    if (validDuplCurr()) {
      // 0406 수정
      //0407 추가
      if (props.frgnAccount.accnRegProgStatCd != 'C') {
        if (chgAccnInfo.wktDivsCd != 'A') {
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00452', '__선택한 통화와 동일한 계좌가 처리진행 중입니다'),
          });
          return;
        }
      }
    }

    if (
      !validFiles(
        [fileDropzoneRef1, fileDropzoneRef2, fileDropzoneRef3], //, fileDropzoneRef4, fileDropzoneRef5
        [
          t('com.label.00453', '__계좌등록요청서'),
          t('com.label.00659', '__은행계좌확인서 또는 계좌사본(인증시)'),
          t('com.label.00284', '__국가별법인등록증'),
        ] //, '기타1', '기타2'
      )
    ) {
      return;
    }

    // 해외 벤더 외화 SWIFT/BIC KR일 경우 계좌 예금주 확인 막기 20241115
    // TODO 예금주 확인
    if (
      chgAccnInfo.dmstFrgDivsCd == 'DO' &&
      props.vendorInfo.soprMgrCopCd == 'HQ00' &&
      chgAccnInfo.bankLoctCtryCd == 'KR'
    ) {
      if (chgAccnInfo.isDpstNmCert == 'Y') {
        if (chgAccnInfo.isDpstNmCertRslt != 'Y') {
          openCommonModal({
            modalType: 'alert',
            content: '계좌를 인증해주세요',
          });
          return;
        }
      }
    }

    const schema = props.isKrw ? regSchemaKrw : dmstFrgDivsCd == 'DO' ? regSchemaDo : regSchema;

    schema
      .validate(chgAccnInfo)
      .then(async () => {
        // 2차 검수 피드백 : 저장노티 띄우지 않고 바로 '저장되었습니다' 메세지 노출
        // 수정자 : 양지원
        try {
          openLoading(true);

          if (!props.isKrw) {
            // 외화 등록인 경우 (해외 사용자 원화 등록이 아닌 경우)
            if (dmstFrgDivsCd == 'DO') {
              console.log('국내 사용자 외화등록');
              // 1. 국내 사용자 외화 등록인 경우

              //        I_LIFNR           CHAR   10   구매처코드 FOR KRW(필수)
              //        I_ACCT_TYPE		CHAR	1	계좌구분	KRW(필수)
              //        I_MODE		CHAR	1	변경유형	FOR KRW(필수)
              //        I_BANKS_N		CHAR	3	신규_은행국가키	FOR(필수)
              //        I_BANKL_N		CHAR	15	신규_은행 키	FOR(필수)
              //        I_BANKN_N		CHAR	38	신규_은행계좌번호	FOR KRW(필수)
              //        I_BVTYP_N		CHAR	4	신규_계좌통화	FOR(필수)
              //        I_KOINH_N		CHAR	60	신규_예금주명	FOR(필수)
              //        I_KOINH_EXT_N		CHAR	35	신규_계정보유자이름	FOR(필수)
              //        I_BANKS_O		CHAR	3	기존_은행국가키	FOR(수정일떄만 필수)
              //        I_BANKL_O		CHAR	15	기존_은행 키	FOR KRW(수정일떄만 필수)
              //        I_BANKN_O		CHAR	38	기존_은행계좌번호	FOR KRW(수정일떄만 필수)
              //        I_BVTYP_O		CHAR	4	기존_은행통화	FOR(수정일떄만 필수)
              //        I_KOINH_O		ZCHAR	60	기존_예금주명	FOR(수정일떄만 필수)
              //        I_KOINH_EXT_O		CHAR	35	기존_계정보유자이름	FOR(수정일떄만 필수)
              //        I_BKONT_O		CHAR	3	기존_은행코드(원화)	KRW(수정일떄만 필수)
              //        I_BKONT_N		CHAR	3	신규_은행코드(원화)	KRW(필수)

              // const resultValid = await getAccnValid0237({
              //   I_REQ_DIVS: 'FOR', // 원화 외화 구분
              //   I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
              //   I_MODE: chgAccnInfo.wktDivsCd, //변경유형
              //   I_BANKS_N: chgAccnInfo.bankLoctCtryCd, //신규 은행국가키
              //   I_BANKL_N: chgAccnInfo.bankKeyCd, // 신규은행키
              //   I_BANKN_N: chgAccnInfo.encAccnNo, //신규_은행계좌번호
              //   I_BVTYP_N: chgAccnInfo.currCd, // 신규은행통화
              //   I_KOINH_N: chgAccnInfo.dpstNm, // 신규예금주명
              //   I_KOINH_EXT_N: chgAccnInfo.addDpstNm, // 신규 계정보유자이름 (추가 예금주명?)
              //   I_BANKS_O: chgAccnInfo.bfBankCtryCd, //기존_은행국가키
              //   I_BANKL_O: chgAccnInfo.bfBankKeyCd, // 기존_은행 키	FOR KRW(수정일떄만 필수)
              //   I_BANKN_O: chgAccnInfo.bfEncAccnNo, // 기존_은행계좌번호	FOR KRW(수정일떄만 필수)
              //   I_BVTYP_O: chgAccnInfo.bfCurrCd, // 기존_은행통화	FOR(수정일떄만 필수)
              //   I_KOINH_O: chgAccnInfo.bfDpstNm, // 기존_예금주명	FOR(수정일떄만 필수)
              //   I_KOINH_EXT_O: chgAccnInfo.bfAddDpstNm, // 기존_계정보유자이름	FOR(수정일떄만 필수)
              // });

              // if (resultValid.stat == 'S') {
              //   setChgAccnInfo((prev) => {
              //     return {
              //       ...prev,
              //       accnNoCertYn: 'Y', // 계좌인증여부
              //     };
              //   });
              //   setCertFlag(true);
              // } else {
              //   openCommonModal({
              //     modalType: 'alert',
              //     content: resultValid.msg,
              //   });
              //   return;
              // }
            } else {
              //        I_MODE		CHAR	1	변경유형 (N / U /A )	FOR(필수)
              //        I_BANKS_N		CHAR	3	신규_은행국가키 	FOR(필수)
              //        I_BANKL_N		CHAR	15	신규_은행 키 	FOR(필수)
              //        I_BANKN_N		CHAR	38	신규_은행계좌번호 	FOR(필수)
              //        I_WAERS_N		CHAR	5	신규_계좌통화	FOR(필수)
              //        I_KOINH_N		CHAR	100	신규_예금주명	FOR(필수)
              //        I_BANKS_O		CHAR	3	기존_은행국가키 	FOR(I_MODE=U/A 일때 필수)
              //        I_BANKL_O		CHAR	15	기존_은행 키 	FOR(I_MODE=U/A 일때 필수)
              //        I_BANKN_O		CHAR	38	기존_은행계좌번호 	FOR(I_MODE=U/A 일때 필수)
              //        I_WAERS_O		CHAR	5	기존_은행통화 	FOR(I_MODE=U/A 일때 필수)
              //        I_KOINH_O		CHAR	100	기존_예금주명	FOR(I_MODE=U/A 일때 필수)
              //        I_BUKRS_N		CHAR	4	Company Code	FOR(필수)
              //        I_BUKRS_O		CHAR	4	Company Code	FOR(I_MODE=U/A 일때 필수)
              //        I_IBAN_N		CHAR	34	IBAN (International Bank Account Number)	FOR(I_ZTYPE = 'I'일때만필수)
              //        I_ZTYPE		CHAR	1	[BIZWF] 계좌구분(I /N)	FOR(필수)

              // 2. 해외 사용자 외화 등록인 경우 GFI0237
              console.log('해외 사용자 외화 등록');
              // const resultValid = await getAccnValidG0237({
              //   I_REQ_DIVS: 'FOR', // 원화 외화 구분
              //   I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
              //   I_MODE: chgAccnInfo.wktDivsCd,
              //   I_BANKS_N: chgAccnInfo.bankCtryCd, // 신규_은행국가키 	FOR(필수)
              //   I_BANKL_N: chgAccnInfo.bankKeyCd, // 신규_은행 키 	FOR(필수)
              //   I_BANKN_N: chgAccnInfo.encAccnNo, // 신규_은행계좌번호 	FOR(필수)
              //   I_WAERS_N: chgAccnInfo.currCd, // 신규_계좌통화	FOR(필수)
              //   I_KOINH_N: chgAccnInfo.dpstNm, // 신규_예금주명	FOR(필수)
              //   I_BANKS_O: chgAccnInfo.bfBankCtryCd, //	기존_은행국가키 	FOR(I_MODE=U/A 일때 필수)
              //   I_BANKL_O: chgAccnInfo.bfBankKeyCd, // 기존_은행 키 	FOR(I_MODE=U/A 일때 필수)
              //   I_BANKN_O: chgAccnInfo.bfEncAccnNo, // 기존_은행계좌번호 	FOR(I_MODE=U/A 일때 필수)
              //   I_WAERS_O: chgAccnInfo.bfCurrCd, //	기존_은행통화 	FOR(I_MODE=U/A 일때 필수)
              //   I_KOINH_O: chgAccnInfo.bfDpstNm, //	기존_예금주명	FOR(I_MODE=U/A 일때 필수)
              // });
              // if (resultValid.stat == 'S') {
              //   setChgAccnInfo((prev) => {
              //     return {
              //       ...prev,
              //       accnNoCertYn: 'Y', // 계좌인증여부
              //     };
              //   });
              //   setCertFlag(true);
              // } else {
              //   //TODO
              //   openCommonModal({
              //     modalType: 'alert',
              //     content: resultValid.msg,
              //   });
              //   return;
              // }
            }
          } else {
            console.log('해외 사용자 원화 등록');

            // 해외사용자 원화 등록인 경우 GFI0237
            // const resultValid = await getAccnValidG0237({
            //   I_REQ_DIVS: 'FOR', // 원화 외화 구분
            //   I_LIFNR: props.vendorInfo.vdcpCd, //구매처코드
            //   I_MODE: chgAccnInfo.wktDivsCd,
            //   I_BANKS_N: chgAccnInfo.bankCtryCd, // 신규_은행국가키 	FOR(필수)
            //   I_BANKL_N: chgAccnInfo.bankKeyCd, // 신규_은행 키 	FOR(필수)
            //   I_BANKN_N: chgAccnInfo.encAccnNo, // 신규_은행계좌번호 	FOR(필수)
            //   I_WAERS_N: chgAccnInfo.currCd, // 신규_계좌통화	FOR(필수)
            //   I_KOINH_N: chgAccnInfo.dpstNm, // 신규_예금주명	FOR(필수)
            //   I_BANKS_O: chgAccnInfo.bfBankCtryCd, //	기존_은행국가키 	FOR(I_MODE=U/A 일때 필수)
            //   I_BANKL_O: chgAccnInfo.bfBankKeyCd, // 기존_은행 키 	FOR(I_MODE=U/A 일때 필수)
            //   I_BANKN_O: chgAccnInfo.bfEncAccnNo, // 기존_은행계좌번호 	FOR(I_MODE=U/A 일때 필수)
            //   I_WAERS_O: chgAccnInfo.bfCurrCd, //	기존_은행통화 	FOR(I_MODE=U/A 일때 필수)
            //   I_KOINH_O: chgAccnInfo.bfDpstNm, //	기존_예금주명	FOR(I_MODE=U/A 일때 필수)
            // });
            // if (resultValid.stat == 'S') {
            //   setChgAccnInfo((prev) => {
            //     return {
            //       ...prev,
            //       accnNoCertYn: 'Y', // 계좌인증여부
            //     };
            //   });
            //   setCertFlag(true);
            // } else {
            //   openCommonModal({
            //     modalType: 'alert',
            //     content: resultValid.msg,
            //   });
            //   return;
            // }
          }

          await getAccountFileIds();
          await modifyForeignAccount(chgAccnInfo, files, dtlFrgnAccount).then((resp) => {
            if (resp.successOrNot === 'Y') {
              openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
              props.save(true);
            }
          });
        } catch (e) {
          console.log('e', e);
          return;
        } finally {
          openLoading(false);
        }
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return;
      });
  };

  /**
   * 첨부파일 필수여부 체크
   */
  const validFiles = (arrRef, arrMsg) => {
    let flag = true;
    for (let index = 0; index < arrRef.length; index++) {
      const o = arrRef[index];
      if (!o.current.getFiles().filter((o) => o.useYn == 'Y').length) {
        flag = false;
        openCommonModal({
          modalType: 'alert',
          content: `${arrMsg[index]} ${t('com.label.00454', '__첨부파일을 추가하세요')}`,
        });
        break;
      }
    }
    return flag;
  };

  const validInput = async (regAccnInfo) => {
    let infoFlag = false;
    regSchema
      .validate(regAccnInfo)
      .then(() => {
        infoFlag = true;
        return infoFlag;
      })
      .catch((e) => {
        console.log('error : ', e);
        openCommonModal({
          modalType: 'alert',
          content: e.message,
        });
        return infoFlag;
      });
  };

  const getFileId = useCallback(async (dropzoneRef) => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    return fileSaveResponse.fileSaveResult == FileSaveResult.FAIL
      ? null
      : fileSaveResponse.atchFileGrId;
  }, []);

  const getAccountFileIds = async () => {
    if (fileDropzoneRef1.current.getFiles().length) {
      await getFileId(fileDropzoneRef1).then((respFileId) => {
        if (respFileId) {
          files.fileId1 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId1: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef2.current.getFiles().length) {
      await getFileId(fileDropzoneRef2).then((respFileId) => {
        if (respFileId) {
          files.fileId2 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId2: respFileId };
          });
        }
      });
    }
    if (fileDropzoneRef3.current.getFiles().length) {
      await getFileId(fileDropzoneRef3).then((respFileId) => {
        if (respFileId) {
          files.fileId3 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId3: respFileId };
          });
        }
      });
    }
    // if (fileDropzoneRef4.current.getFiles().length) {
    //   await getFileId(fileDropzoneRef4).then((respFileId) => {
    //     if (respFileId) {
    //       files.fileId4 = respFileId;
    //       setChgAccnInfo((prev) => {
    //         return { ...prev, fileId4: respFileId };
    //       });
    //     }
    //   });
    // }
    if (fileDropzoneRef5.current.getFiles().length) {
      await getFileId(fileDropzoneRef5).then((respFileId) => {
        if (respFileId) {
          files.fileId5 = respFileId;
          setChgAccnInfo((prev) => {
            return { ...prev, fileId5: respFileId };
          });
        }
      });
    }
  };

  const getFrgnBankCd = async (swiftCd) => {
    openLoading(true);

    if (dmstFrgDivsCd == 'DO') {
      getSapIfStdInfo({
        I_REQ_DIVS: 'BK',
        I_SWIFT: swiftCd,
        I_LAND: props.isKrw ? 'KR' : '',
      }).then((resp) => {
        openLoading(false);
        if (resp.length > 0) {
          const tmpBankCd = resp.filter((o) => o.bgrup != null)[0].bgrup;
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: tmpBankCd ?? '',
            };
          });
        } else {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: '',
            };
          });
        }
      });
    } else {
      getSapIfStdInfoG({
        I_REQ_DIVS: 'BK',
        I_SWIFT: swiftCd,
        I_LAND: props.isKrw ? 'KR' : '',
        I_LIFNR: props.frgnAccount.vdcpCd,
      }).then((resp) => {
        openLoading(false);
        if (resp.length > 0) {
          const tmpBankCd = resp.filter((o) => o.bgrup != null)[0].bgrup;
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: tmpBankCd ?? '',
              isDpstNmCert: tmpBankCd ? 'Y' : 'N',
            };
          });
        } else {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankCd: '',
              isDpstNmCert: props.isKrw ? 'Y' : 'N', // 계좌인증
            };
          });
        }
      });
    }
  };

  const emailValid = () => {
    // 이메일 형식
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    const emails = [chgAccnInfo.bankEml];

    const invalidEmails = emails.filter((email) => email && !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      openMessageBar('warning', t('com.label.00230', '__이메일 형식이 올바르지 않습니다.'));
      return false;
    }
    return true;
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForChgAccn = (e) => {
    // if (props.vendorInfo.dmstFrgDivsCd == 'DO') {
    //   if (e.target.id == 'dpstNm' || e.target.id == 'addDpstNm') {
    //     e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
    //     e.target.value = e.target.value.replace(/[^a-zA-Z0-9.,()\-_+|:?/ ]+/g, ''); //특수문자 입력
    //   }
    // }

    const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '/', '[', ']', '{', '}'];
    // 예금주 대문자 변환 0319
    if (e.target.id == 'dpstNm') {
      e.target.value = e.target.value.replace(/[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); //한글 입력 방지
      // e.target.value = e.target.value.replace(/[^\x00-\x7F\u4E00-\u9FA5]+/g, ''); //한글 입력 방지, 중문 입력가능

      const replacedString = e.target.value.replace(
        new RegExp(`[${specialChars.join('\\')}]`, 'g'),
        ''
      );
      e.target.value = replacedString;

      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });

      setChgAccnInfo((prev) => {
        return {
          ...prev,
          addAtrValCtn06: 'N',
          isDpstNmCertRslt: 'N',
        };
      });
    }

    // 국내 외화 계좌 은행명
    if (dmstFrgDivsCd == 'DO') {
      if (e.target.id == 'bankNm') {
        e.target.value = e.target.value.replace(/[äöüßÄÖÜ\uFF08\uFF09]/g, '');
        setChgAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }
    }

    if (dmstFrgDivsCd == 'OV') {
      if (e.target.id == 'bankNm') {
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~\uFF08\uFF09]/g,
          ''
        );
        setChgAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }
      if (e.target.id == 'bankAddr') {
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~\uFF08\uFF09]/g,
          ''
        );
        setChgAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }

      if (e.target.id == 'bankEml') {
        // 한글과 특정 특수문자를 제거하는 정규식
        e.target.value = e.target.value.replace(
          /[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣!#$%^&*()=+{}[\]:;"'<>,?/\\|`~]/g,
          ''
        );

        setChgAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }

      if (e.target.id == 'usePurpCtn') {
        e.target.value = e.target.value.replace(/[äöüßÄÖÜㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); //한글 입력 방지

        setChgAccnInfo((prev) => {
          return {
            ...prev,
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }
    }

    // 해외벤더로 로그인했을때 Address of Bank 한국어 못들어가도록
    // if (dmstFrgDivsCd == 'OV' && !props.isKrw) {
    //   if (e.target.id == 'bankAddr') {
    //     e.target.value = e.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); //한글 입력 방지

    //     const replacedString = e.target.value.replace(
    //       new RegExp(`[${specialChars.join('\\')}]`, 'g'),
    //       ''
    //     );
    //     e.target.value = replacedString;

    //     setChgAccnInfo((prev) => {
    //       return {
    //         ...prev,
    //         addAtrValCtn06: 'N',
    //         isDpstNmCertRslt: 'N',
    //       };
    //     });
    //   }
    // }

    if (e.target.id == 'encAccnNo') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });
      setChgAccnInfo((prev) => {
        return {
          ...prev,
          addAtrValCtn06: 'N',
          isDpstNmCertRslt: 'N',
        };
      });
    }

    if (e.target.id == 'ibanCd') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });
      setChgAccnInfo((prev) => {
        return {
          ...prev,
          addAtrValCtn06: 'N',
          isDpstNmCertRslt: 'N',
        };
      });
    }

    if (e.target.id == 'swftCd') {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
      e.target.value = e.target.value.replace(/[a-z]/g, (match) => {
        return match.toUpperCase();
      });
      const tmpVal = e.target.value;
      if (tmpVal.length >= 6) {
        const tmpBankLoctCtryCd = tmpVal.substring(4, 6);
        if (bankLoctCtryCdList.some((o) => o.cmnCd == tmpBankLoctCtryCd)) {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankLoctCtryCd: tmpBankLoctCtryCd,
              bankCtryCd: tmpBankLoctCtryCd,
              addAtrValCtn06: 'N',
              isDpstNmCertRslt: 'N',
            };
          });
        }
      }
    }

    if (e.target.id == 'bankCtpl') {
      e.target.value = e.target.value.replace(/[^\d]/g, '');
    }

    setChgAccnInfo((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const handleBlurSwiftCd = (e) => {
    const tmpVal = e.target.value;
    if (tmpVal.length >= 6) {
      const tmpBankLoctCtryCd = tmpVal.substring(4, 6);
      if ('KR' == tmpBankLoctCtryCd) {
        getFrgnBankCd(tmpVal);
      } else {
        setChgAccnInfo((prev) => {
          return { ...prev, isDpstNmCert: props.isKrw ? 'Y' : 'N', bankCd: '' };
        });
      }
    } else {
      setChgAccnInfo((prev) => {
        return { ...prev, isDpstNmCert: props.isKrw ? 'Y' : 'N', bankCd: '' };
      });
    }
  };

  const handleChangeCurrCd = (e) => {
    const selectedValue = e.target.value;
    const innerHtml = t('com.label.01317', '__계좌변경은 동일한 통화만 가능합니다.', {
      currCd: selectedValue, // 다국어 팝업에 통화 파라미터 세팅
    });

    if (
      dtlFrgnAccount?.addAtrValCtn03 !== 'N' &&
      dtlFrgnAccount?.addAtrValCtn03 !== 'A' &&
      selectedValue !== dtlFrgnAccount?.currCd // 기존 통화와 다를 때만 모달 표시
    ) {
      openCommonModal({
        modalType: 'alert',
        content: (
          <div
            style={{ whiteSpace: 'pre-wrap' }} // 다국어 개행처리를 위함
            dangerouslySetInnerHTML={{ __html: innerHtml }}
          ></div>
        ),
      });

      // 기존 통화로 복원
      setChgAccnInfo((prev) => ({
        ...prev,
        currCd: dtlFrgnAccount?.currCd,
        addAtrValCtn06: 'N',
        isDpstNmCertRslt: 'N',
      }));
    } else {
      // 정상적으로 선택한 통화 반영
      setChgAccnInfo((prev) => ({
        ...prev,
        currCd: selectedValue,
        addAtrValCtn06: 'N',
        isDpstNmCertRslt: 'N',
      }));
    }
  };

  const handleChangeWktDivsCd = (e) => {
    setChgAccnInfo((prev) => {
      return {
        ...prev,
        wktDivsCd: e.target.value,
      };
    });
  };

  useEffect(() => {
    (async () => {
      openLoading(true);
      // 공통코드 조회 (계좌추가정보)
      await getJwtCommonCodes('BANK_NO_TP_CD')
        .then((resp) => {
          openLoading(false);
          setBankNoTpCdList(resp);

          if (props.isKrw) {
            setChgAccnInfo((prev) => {
              return { ...prev, bankNoTpCd: '9' };
            });
          } else {
            //0404 수정
            // setChgAccnInfo((prev) => {
            //   return { ...prev, bankNoTpCd: resp[0].cmnCd };
            // });
          }
        })
        .catch((e) => {
          openLoading(false);
        });

      // 공통코드 조회 (국가키)
      await getJwtCommonCodes('BANK_LOCT_CTRY_CD')
        .then((resp) => {
          openLoading(false);
          setBankLoctCtryCdList(resp);
          if (props.isKrw) {
            setChgAccnInfo((prev) => {
              return { ...prev, bankLoctCtryCd: 'KR', bankCtryCd: 'KR' };
            });
          } else {
            // setChgAccnInfo((prev) => {
            //   return { ...prev, bankLoctCtryCd: resp[0].cmnCd };
            // });
          }
        })
        .catch((e) => {
          openLoading(false);
        });

      if (!props.isKrw) {
        if (dmstFrgDivsCd == 'DO') {
          openLoading(true);
          await getSapIfStdInfo({ I_REQ_DIVS: 'CUR' })
            .then((resp) => {
              openLoading(false);
              if (resp.length > 0) {
                const computedArr = resp.reduce((acc, obj) => {
                  const newObj = {
                    cmnCd: obj.waers,
                    cmnCdNm: obj.ltext,
                  };
                  if (obj.waers != 'KRW') {
                    acc.push(newObj);
                  }
                  return acc;
                }, []);
                setCurCdList(computedArr);
                openLoading(false);
              }
            })
            .catch((e) => {
              openLoading(false);
            });
        } else {
          openLoading(true);
          await getSapIfStdInfoG({ I_REQ_DIVS: 'CUR', I_LIFNR: props.frgnAccount.vdcpCd })
            .then((resp) => {
              openLoading(false);
              if (resp.length > 0) {
                const computedArr = resp.reduce((acc, obj) => {
                  const newObj = {
                    cmnCd: obj.waers,
                    cmnCdNm: obj.ltext,
                  };
                  if (obj.waers != 'KRW') {
                    acc.push(newObj);
                  }
                  return acc;
                }, []);
                setCurCdList(computedArr);
              }
            })
            .catch((e) => {
              openLoading(false);
            });
        }
      }
      if (props.frgnAccount.accnRegReqId) {
        // db에 데이터가 있는 경우
        openLoading(true);
        getForeignAccount(props.frgnAccount.vdcpCd, props.frgnAccount.accnRegReqId)
          .then((resp) => {
            openLoading(false);
            if (resp) {
              setDtlFrgnAccount(resp);
              setChgAccnInfo((prev) => {
                return {
                  ...prev,
                  ...resp,
                  bankKeyCd: '', //뱅크키 초기화
                  bankCd: '', //0404 수정
                  addAtrValCtn06: 'N',
                  isDpstNmCertRslt: 'N',
                };
              });
            }
          })
          .catch((e) => {
            openLoading(false);
          });
      } else {
        //TODO db에 데이터가 없는 경우
        setDtlFrgnAccount({
          ...props.frgnAccount,
          // bfBankNm: props.frgnAccount.oldBankNm,
          // bfEncAccnNo: props.frgnAccount.oldEncAccnNo,
          // bfDpstNm: props.frgnAccount.oldDpstNm,
          // bfCopCd: props.frgnAccount.oldCopCd,
          // bfCopNm: props.frgnAccount.oldCopNm,
          // bfBankCd: props.frgnAccount.oldBankCd,
          // bfBankKeyCd: props.frgnAccount.oldBankKeyCd,
          // bfBankCtryCd: props.frgnAccount.oldBankCtryCd,
          // bfAddDpstNm: props.frgnAccount.oldAddDpstNm,
          // bfCurrCd: props.frgnAccount.oldBfCurrCd,
          // bfPtnrBankTpId: props.frgnAccount.oldBfPtnrBankTpId,
          // bfBankBrchCd: props.frgnAccount.oldBankBrchCd,
          // bfIbanCd: props.frgnAccount.oldIbanCd,
          txCd: props.vendorInfo.copRegNo,
          currCd: props.isKrw ? 'KRW' : props.frgnAccount.currCd ?? 'USD',
          // aprRqrEmpNo: 'FP501318',
        });
        setChgAccnInfo((prev) => {
          return {
            ...prev,
            ...props.frgnAccount,
            currCd: props.isKrw ? 'KRW' : props.frgnAccount.currCd ?? 'USD',
            txCd: props.vendorInfo.copRegNo,
            bankLoctCtryCd: props.frgnAccount.bankLoctCtryCd,
            bankKeyCd: '', //뱅크키 초기화
            bankCd: '', //0404 수정
            addAtrValCtn06: 'N',
            isDpstNmCertRslt: 'N',
          };
        });
      }

      let tmpWktDivsCdList = [
        { cmnCdNm: t('com.code.WKT_DIVS_CD.N', '__New'), cmnCd: 'N' },
        { cmnCdNm: t('com.code.WKT_DIVS_CD.U', '__Amendent'), cmnCd: 'U' },
      ];

      if (props.frgnAccount.addAtrValCtn03 == 'N') {
        tmpWktDivsCdList = [{ cmnCdNm: t('com.code.WKT_DIVS_CD.N', '__New'), cmnCd: 'N' }];
      }

      if (props.frgnAccount.addAtrValCtn03 == 'U') {
        tmpWktDivsCdList = [{ cmnCdNm: t('com.code.WKT_DIVS_CD.U', '__Amendent'), cmnCd: 'U' }];
      }

      if (props.frgnAccount.addAtrValCtn03 == 'A') {
        tmpWktDivsCdList = [{ cmnCdNm: t('com.code.WKT_DIVS_CD.A', '__Addition'), cmnCd: 'A' }];
      }

      //0330 추가 방어 코드
      if (props.frgnAccount.accnRegProgStatCd == 'R' && props.frgnAccount.addAtrValCtn03 == 'N') {
        tmpWktDivsCdList = [{ cmnCdNm: t('com.code.WKT_DIVS_CD.N', '__New'), cmnCd: 'N' }];
      }

      //0406 추가
      if (props.frgnAccount.accnRegProgStatCd == 'C') {
        tmpWktDivsCdList = [{ cmnCdNm: t('com.code.WKT_DIVS_CD.U', '__Amendent'), cmnCd: 'U' }];
      }

      setWktDivsCdList(tmpWktDivsCdList);
    })();
  }, []);

  useEffect(() => {
    if (certFlag) {
      setChgAccnInfo((prev) => {
        return {
          ...prev,
          accnNoCertYn: 'Y', // 계좌인증여부
        };
      });
    }
  }, [certFlag]);

  useEffect(() => {
    if (chgAccnInfo.bankCd) {
      setChgAccnInfo((prev) => {
        return {
          ...prev,
          isDpstNmCert: 'Y', // 계좌인증
        };
      });
    } else {
      setChgAccnInfo((prev) => {
        return {
          ...prev,
          isDpstNmCert: props.isKrw ? 'Y' : 'N', // 계좌인증
        };
      });
    }
  }, [chgAccnInfo.bankCd]);

  const getLeftTitle = () => {
    const stcd = props.frgnAccount.accnRegProgStatCd;
    let result;

    switch (stcd) {
      case 'C':
        result = t('com.code.preAccnDtls', '__기등록계좌상세정보');
        break;
      case 'T':
        result = t('com.code.tempStgAccnDtls', '__임시저장계좌 상세정보');
        break;
      case 'R':
        result = t('com.code.rjctAccnDtls', '__반려계좌 상세정보');
        break;
    }

    return result;
  };

  // PIF 출력폼
  const { handleClickPif, setBankInfo, setBasicVendorInfo } = usePifPrint();

  useEffect(() => {
    setBasicVendorInfo(props.vendorInfo);
  }, []);

  useEffect(() => {
    setBankInfo(chgAccnInfo);
  }, [chgAccnInfo]);

  return (
    <>
      <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1600} mMaxWidth={1600}>
        {dtlFrgnAccount && (
          <>
            <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
              <GPopupTitle title2={t('com.label.00455', '__계좌정보상세')} />
            </DialogTitleItem>
            <DialogContent className="popupContent">
              <GridBox>
                <GridItem>
                  <SubTitleBtnArea>
                    <SubTitleBox showSubTitle={true} content={getLeftTitle()} />
                  </SubTitleBtnArea>
                  <GbasicTableWrap>
                    <GbasicTable>
                      <colgroup>
                        <col style={{ width: '120px' }} />
                        <col style={{ width: 'auto' }} />
                        <col style={{ width: '120px' }} />
                        <col style={{ width: 'auto' }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>
                            <GLabelAtom align="left">{t('com.label.00065', '__은행')}</GLabelAtom>
                          </th>
                          <td colSpan={2}>
                            {dtlFrgnAccount?.accnRegProgStatCd == 'R' ||
                            dtlFrgnAccount?.accnRegProgStatCd == 'C'
                              ? dtlFrgnAccount?.bankNm
                              : dtlFrgnAccount?.oldBankNm ?? dtlFrgnAccount?.bankNm}
                          </td>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00064', '__진행상태')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <Tag
                              content={dtlFrgnAccount?.accnRegProgStatCdNm}
                              status={getTagStatus(dtlFrgnAccount?.accnRegProgStatCd ?? '')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left">{t('com.label.00067', '__예금주')}</GLabelAtom>
                          </th>
                          <td colSpan={2} style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {dtlFrgnAccount?.accnRegProgStatCd == 'R' ||
                            dtlFrgnAccount?.accnRegProgStatCd == 'C'
                              ? dtlFrgnAccount?.dpstNm
                              : dtlFrgnAccount?.oldDpstNm ?? dtlFrgnAccount?.dpstNm}
                          </td>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00069', '__예금주 확인')}
                            </GLabelAtom>
                          </th>
                          <td>
                            {dtlFrgnAccount?.accnRegProgStatCd == 'R' ||
                            dtlFrgnAccount?.accnRegProgStatCd == 'C'
                              ? dtlFrgnAccount?.addDpstNm
                              : dtlFrgnAccount?.oldAddDpstNm ?? dtlFrgnAccount?.addDpstNm}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00070', '__계좌번호')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={2}>
                            {dtlFrgnAccount?.accnRegProgStatCd == 'R' ||
                            dtlFrgnAccount?.accnRegProgStatCd == 'C'
                              ? dtlFrgnAccount?.encAccnNo
                              : dtlFrgnAccount?.oldEncAccnNo ?? dtlFrgnAccount?.encAccnNo}
                          </td>
                          <th>
                            <GLabelAtom align="left">{t('com.label.00076', '__통화')}</GLabelAtom>
                          </th>
                          <td>{dtlFrgnAccount?.currCd}</td>
                        </tr>
                        {dtlFrgnAccount?.isErpOnly == 'N' && (
                          <>
                            <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.code.bankAddr', '__은행주소')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={3}>{dtlFrgnAccount?.bankAddr}</td>
                            </tr>
                            <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.code.bankCntNum', '__은행연락처')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={2}>{dtlFrgnAccount?.bankCtpl}</td>
                              <th>
                                <GLabelAtom align="left">
                                  {t2('com.code.bankEmal', '__은행이메일')}
                                </GLabelAtom>
                              </th>
                              <td>{dtlFrgnAccount?.bankEml}</td>
                            </tr>
                            {props.vendorInfo.dmstFrgDivsCd == 'OV' && !props.isKrw && (
                              <tr>
                                <th>
                                  <GLabelAtom align="left">
                                    {t('com.code.bankAddIfo', '__계좌추가정보')}
                                  </GLabelAtom>
                                </th>
                                <td colSpan={3}>
                                  {dtlFrgnAccount.bankNoTpCdNm}&emsp;&emsp;
                                  {dtlFrgnAccount.bankNo}
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <tr>
                          <th>
                            <GLabelAtom align="left">SWIFT/BIC</GLabelAtom>
                          </th>
                          <td colSpan={2}>{dtlFrgnAccount?.swftCd}</td>
                          {props.vendorInfo.dmstFrgDivsCd == 'OV' && !props.isKrw && (
                            <>
                              <th>
                                <GLabelAtom align="left">IBAN Code</GLabelAtom>
                              </th>
                              <td colSpan={2}>
                                {dtlFrgnAccount?.accnRegProgStatCd == 'R' ||
                                dtlFrgnAccount?.accnRegProgStatCd == 'C'
                                  ? dtlFrgnAccount?.ibanCd
                                  : dtlFrgnAccount?.oldIbanCd ?? dtlFrgnAccount?.ibanCd}
                              </td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00071', '__계좌용도')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={3}>
                            {dtlFrgnAccount?.accnRegProgStatCd == 'R' ||
                            dtlFrgnAccount?.accnRegProgStatCd == 'T'
                              ? dtlFrgnAccount?.usePurpCtn
                              : dtlFrgnAccount?.usePurpCtn}
                          </td>
                        </tr>

                        {dtlFrgnAccount?.isErpOnly == 'N' && (
                          <>
                            <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.label.00453', '__계좌등록요청서')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={3} style={{ verticalAlign: 'top' }}>
                                <FileDownloadVars atchFileGrId={dtlFrgnAccount?.fileId1 ?? ''} />
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.label.00659', '__은행계좌확인서 또는 계좌사본(인증시)')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={3} style={{ verticalAlign: 'top' }}>
                                <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId2 ?? ''} />
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.label.00284', '__국가별법인등록증')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={3} style={{ verticalAlign: 'top' }}>
                                <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId3 ?? ''} />
                              </td>
                            </tr>
                            {/* <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.label.00075', '__기타')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={3}>
                                <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId4 ?? ''} />
                              </td>
                            </tr> */}
                            <tr>
                              <th>
                                <GLabelAtom align="left">
                                  {t('com.label.00075', '__기타')}
                                </GLabelAtom>
                              </th>
                              <td colSpan={3}>
                                <FileDownloadVars atchFileGrId={dtlFrgnAccount.fileId5 ?? ''} />
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </GbasicTable>
                  </GbasicTableWrap>
                </GridItem>
                <GridItem>
                  <SubTitleBtnArea>
                    <SubTitleBox
                      showSubTitle={true}
                      content={t('com.label.00456', '__계좌상세정보')}
                    />
                  </SubTitleBtnArea>
                  {!props.isKrw && (
                    <GbasicTableWrap style={{ marginBottom: '12px' }}>
                      <GbasicTable>
                        <colgroup>
                          <col style={{ width: '120px' }} />
                          <col style={{ width: 'auto' }} />
                          <col style={{ width: '120px' }} />
                          <col style={{ width: 'auto' }} />
                        </colgroup>
                        <tbody>
                          <>
                            <tr>
                              <th>
                                <GLabelAtomVars
                                  align="left"
                                  isInfo={true}
                                  pTxt={
                                    t(
                                      'com.tooltip.accn.fcur.reg05',
                                      '__ㆍ New: When you are registering a new bank account.\r\nㆍ Addition: If you already have an account registered in the same currency and you want to add another account.'
                                    ) ?? ''
                                  }
                                  pPlace="3"
                                >
                                  {t('com.code.reqClsf', '__요청구분')}
                                </GLabelAtomVars>
                              </th>
                              <td>
                                <GCodeSelect
                                  id={'cmnCd'}
                                  labelKey={'cmnCdNm'}
                                  listData={wktDivsCdList}
                                  onlyShowName={true}
                                  value={chgAccnInfo.wktDivsCd ?? 'U'}
                                  onChange={handleChangeWktDivsCd}
                                  width="150px"
                                />
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </GbasicTable>
                    </GbasicTableWrap>
                  )}
                  <GbasicTableWrap>
                    <GbasicTable>
                      <tbody>
                        {dmstFrgDivsCd == 'OV' && !props.isKrw && (
                          <tr>
                            <th>
                              <GLabelAtom align="left">
                                {t('com.code.accnTp', '__계좌유형')}
                              </GLabelAtom>
                            </th>
                            <td>
                              <GRadioGroup
                                value={chgAccnInfo.ibanYn || 'Normal'}
                                option={['Normal', 'IBAN']}
                                onChange={handleChangeIbanYn}
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th>
                            <GLabelAtom align="left" isRequired>
                              {t('com.label.00067', '__예금주')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <InputField
                              id="dpstNm"
                              status="default"
                              align="left"
                              width="100%"
                              // placeholder={t2('com.label.00068', '__입력하세요')}
                              value={chgAccnInfo.dpstNm}
                              maxLength={120}
                              onChange={handleChangeForChgAccn}
                            />
                          </td>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00069', '__예금주 확인')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <InputField
                              id="addDpstNm"
                              status="readonly"
                              readOnly
                              align="left"
                              width="100%"
                              value={chgAccnInfo.addDpstNm}
                              maxLength={30}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>
                            <GLabelAtom align="left" isRequired={chgAccnInfo.ibanYn == 'Normal'}>
                              {t('com.label.00070', '__계좌번호')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <ItemAlignWrap>
                              <InputField
                                id="encAccnNo"
                                status={chgAccnInfo.ibanYn == 'Normal' ? 'default' : 'readonly'}
                                readOnly={chgAccnInfo.ibanYn != 'Normal'}
                                align="left"
                                width="100%"
                                value={chgAccnInfo.encAccnNo}
                                placeholder={
                                  chgAccnInfo.ibanYn == 'Normal'
                                    ? t2('com.label.00377', `__'-'없이 입력하세요`)
                                    : ''
                                }
                                maxLength={100}
                                onChange={handleChangeForChgAccn}
                              />
                              {chgAccnInfo.dmstFrgDivsCd == 'DO' &&
                                props.vendorInfo.soprMgrCopCd == 'HQ00' &&
                                chgAccnInfo.bankLoctCtryCd == 'KR' &&
                                chgAccnInfo.isDpstNmCert == 'Y' && (
                                  <GButton
                                    txt={t(`com.label.00457`, `__인증요청`)}
                                    sizes="medium"
                                    btnColor="normal"
                                    btnstyled="outline"
                                    hasImg={false}
                                    hasTxt={true}
                                    onClick={() => {
                                      handleValidAccnClick();
                                    }}
                                  />
                                )}
                            </ItemAlignWrap>
                          </td>
                          <th>
                            <GLabelAtom align="left" isRequired>
                              {t('com.label.00076', '__통화')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <GCodeSelect
                              id={'cmnCd'}
                              labelKey={'cmnCd'}
                              // emptyLabel={props.isKrw ? 'KRW' : 'USD'}
                              listData={
                                props.isKrw ? [{ cmnCdNm: 'KRW', cmnCd: 'KRW' }] : curCdList
                              }
                              onlyShowName={true}
                              value={chgAccnInfo.currCd}
                              onChange={handleChangeCurrCd}
                              width="150px"
                              readonly={props.isKrw}
                            />
                          </td>
                        </tr>
                        {dmstFrgDivsCd == 'OV' && !props.isKrw && (
                          <tr>
                            <th>
                              <GLabelAtom align="left" isRequired={chgAccnInfo.ibanYn != 'Normal'}>
                                IBAN CODE
                              </GLabelAtom>
                            </th>
                            <td>
                              <InputField
                                id="ibanCd"
                                status={chgAccnInfo.ibanYn == 'Normal' ? 'readonly' : 'default'}
                                readOnly={chgAccnInfo.ibanYn == 'Normal'}
                                align="left"
                                width="100%"
                                value={chgAccnInfo.ibanCd}
                                placeholder={
                                  chgAccnInfo.ibanYn == 'Normal'
                                    ? ''
                                    : t2('com.label.00068', '__입력하세요')
                                }
                                maxLength={34}
                                onChange={handleChangeForChgAccn}
                              />
                            </td>
                            <th>
                              <GLabelAtomVars
                                align="left"
                                isInfo={true}
                                pTxt={t2(
                                  'com.tooltip.accn.fcur.reg04',
                                  '__ㆍSelect and enter ABA Routing No.(EFT Routing No.), Sort Code, CNAPS Code'
                                )}
                                pPlace="3"
                                isRequired
                              >
                                {t('com.code.bankAddIfo', '__계좌추가정보')}
                              </GLabelAtomVars>
                            </th>
                            <td>
                              <ItemAlignWrap>
                                <GCodeSelect
                                  id={'cmnCd'}
                                  labelKey={'cmnCdNm'}
                                  listData={bankNoTpCdList}
                                  onlyShowName={true}
                                  emptyLabel={t2('com.label.00356', '__선택')}
                                  value={chgAccnInfo.bankNoTpCd}
                                  onChange={handleChangeBankNoTpCd}
                                  width="108px"
                                  // readonly={props.isKrw || chgAccnInfo.ibanYn == 'IBAN'}   // 0404 수정
                                  readonly={props.isKrw}
                                />
                                <InputField
                                  id="bankNo"
                                  status={
                                    chgAccnInfo.bankNoTpCd == '' || chgAccnInfo.bankNoTpCd == '9'
                                      ? 'readonly'
                                      : 'default'
                                  }
                                  align="left"
                                  width="100%"
                                  value={chgAccnInfo.bankNo}
                                  readOnly={
                                    chgAccnInfo.bankNoTpCd == '' || chgAccnInfo.bankNoTpCd == '9'
                                  }
                                  onChange={handleChangeForChgAccn}
                                  maxLength={15}
                                />
                              </ItemAlignWrap>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <th>
                            <GLabelAtom align="left" isRequired={true}>
                              {t('com.label.00065', '__은행')}
                            </GLabelAtom>
                          </th>
                          <td>
                            {/* <ChipInputBox
                              width="100%"
                              status="default"
                              type="singleChip"
                              value={chgAccnInfo.bankNm}
                              setValue={(value) => {
                                if (!value) {
                                  setChgAccnInfo((prev) => {
                                    return {
                                      ...prev,
                                      bankKeyCd: '',
                                      bankNm: '',
                                      bankCtryCd: props.isKrw ? 'KR' : '',
                                      swftCd: '',
                                    };
                                  });
                                }
                              }}
                              onClickFunction={onSearchBankClick}
                            /> */}
                            <InputField
                              id="bankNm"
                              status="default"
                              align="left"
                              width="100%"
                              value={chgAccnInfo.bankNm}
                              onChange={handleChangeForChgAccn}
                              maxLength={100}
                            />
                          </td>
                          <th>
                            <GLabelAtom
                              align="left"
                              isRequired={props.isKrw || chgAccnInfo.dmstFrgDivsCd == 'DO'}
                            >
                              SWIFT/BIC
                            </GLabelAtom>
                          </th>
                          <td>
                            <InputField
                              id="swftCd"
                              status="default"
                              align="left"
                              width="100%"
                              value={chgAccnInfo.swftCd}
                              onChange={handleChangeForChgAccn}
                              onBlur={(e) => {
                                handleBlurSwiftCd(e);
                                e.stopPropagation();
                              }}
                              maxLength={15}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left" isRequired>
                              {t('com.code.bankCtry', '__은행소재국가')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <GCodeSelect
                              id={'cmnCd'}
                              labelKey={'cmnCdNm'}
                              listData={bankLoctCtryCdList}
                              emptyLabel={t2('com.label.00356', '__선택')}
                              onlyShowName={true}
                              value={chgAccnInfo.bankLoctCtryCd}
                              onChange={handleChangeBankLoctCtryCd}
                              width="150px"
                              readonly={props.isKrw}
                            />
                          </td>
                          <th>
                            <GLabelAtom align="left" isRequired>
                              {t('com.code.bankAddr', '__은행주소')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <InputField
                              id="bankAddr"
                              status="default"
                              align="left"
                              width="100%"
                              value={chgAccnInfo.bankAddr}
                              onChange={handleChangeForChgAccn}
                              maxLength={200}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left" isRequired>
                              {t('com.code.bankCntNum', '__은행연락처')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <InputField
                              id="bankCtpl"
                              status="default"
                              align="left"
                              width="100%"
                              value={chgAccnInfo.bankCtpl}
                              onChange={handleChangeForChgAccn}
                              maxLength={15}
                            />
                          </td>
                          <th>
                            <GLabelAtom align="left" isRequired>
                              {t2('com.code.bankEmal', '__은행이메일')}
                            </GLabelAtom>
                          </th>
                          <td>
                            <InputField
                              id="bankEml"
                              status="default"
                              align="left"
                              width="100%"
                              value={chgAccnInfo.bankEml}
                              onChange={handleChangeForChgAccn}
                              maxLength={50}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <GLabelAtom align="left">
                              {t('com.label.00071', '__계좌용도')}
                            </GLabelAtom>
                          </th>
                          <td colSpan={3}>
                            <InputField
                              id="usePurpCtn"
                              status="default"
                              align="left"
                              width="100%"
                              value={chgAccnInfo.usePurpCtn}
                              // placeholder={t2('com.label.00068', '__입력하세요')}
                              onChange={handleChangeForChgAccn}
                              maxLength={25}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </GbasicTable>
                  </GbasicTableWrap>
                  <GbasicTableWrap style={{ marginTop: '12px' }}>
                    <GbasicTable>
                      <tbody>
                        <tr>
                          <th>
                            <GLabelAtomVars
                              align="left"
                              isRequired
                              isInfo={true}
                              pTxt={
                                t(
                                  'com.tooltip.accn.fcur.reg01',
                                  '__ㆍ상단 "Payment Information Form 출력"을 클릭하여 Payment Information Form에 기재된 내용의 이상 여부 확인\r\nㆍ기재된 내용에 이상이 없는 경우 서류를 출력하여 법인인감 날인(한국 소재 기업) 또는 서명하여 첨부하세요.'
                                ) ?? ''
                              }
                              pPlace="3"
                            >
                              {t('com.label.00453', '__계좌등록요청서')}
                            </GLabelAtomVars>
                          </th>
                          <td colSpan={3}>
                            <FileUploadVars
                              ref={fileDropzoneRef1}
                              atchFileGrIdInput={dtlFrgnAccount?.fileId1 ?? ''}
                              isMultipleFile={false}
                              maxCount={1}
                              allowFileTypes={['jpg', 'png', 'pdf']}
                              height="40px"
                              width="100%"
                              getSelectedFile={(value) => {
                                if (value.length == 0) {
                                  files.fileId1 = '';
                                }
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th rowSpan={2}>
                            <GLabelAtomVars
                              align="left"
                              isRequired
                              isInfo={true}
                              pTxt={
                                t(
                                  'com.tooltip.accn.fcur.reg02',
                                  '__은행으로부터 발급받은 은행계좌확인서\r\nㆍ 은행담당자 서명 필수\r\nㆍ 한국 소재 기업인 경우 통장 사본 함께 첨부'
                                ) ?? ''
                              }
                              pPlace="3"
                            >
                              {t('com.label.00659', '__은행계좌확인서 또는 계좌사본(인증시)')}
                            </GLabelAtomVars>
                          </th>
                          <td>
                            <GButton
                              txt={t('com.code.smpDown', '__샘플 다운로드')}
                              chkImg={Download}
                              btnstyled="outline"
                              btnColor="normal"
                              sizes="small"
                              hasTxt={true}
                              hasImg={true}
                              disabled={false}
                              onClick={() => {
                                const url = '/assets/file/AccountComfirmationSample.pdf';
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'AccountComfirmationSample.pdf');
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                URL.revokeObjectURL(url);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <FileUploadVars
                              ref={fileDropzoneRef2}
                              atchFileGrIdInput={dtlFrgnAccount?.fileId2 ?? ''}
                              isMultipleFile={false}
                              maxCount={1}
                              allowFileTypes={['jpg', 'png', 'pdf']}
                              height="40px"
                              width="100%"
                              getSelectedFile={(value) => {
                                if (value.length == 0) {
                                  files.fileId2 = '';
                                }
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th rowSpan={2}>
                            <GLabelAtomVars
                              align="left"
                              isRequired
                              isInfo={true}
                              pTxt={
                                t(
                                  'com.tooltip.accn.fcur.reg03',
                                  '__ㆍ W-9, 사업자등록증 등\r\nㆍ 한국 소재 기업인 경우 3개월 이내 발급한 법인인감증명서 함께 첨부 필수'
                                ) ?? ''
                              }
                              pPlace="3"
                            >
                              {t('com.label.00284', '__국가별법인등록증')}
                            </GLabelAtomVars>
                          </th>
                          <td>
                            <GButton
                              txt={t('com.code.smpDown', '__샘플 다운로드')}
                              chkImg={Download}
                              btnstyled="outline"
                              btnColor="normal"
                              sizes="small"
                              hasTxt={true}
                              hasImg={true}
                              disabled={false}
                              onClick={() => {
                                const url = '/assets/file/sample.pdf';
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'sample.pdf');
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                URL.revokeObjectURL(url);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <FileUploadVars
                              ref={fileDropzoneRef3}
                              atchFileGrIdInput={dtlFrgnAccount?.fileId3 ?? ''}
                              isMultipleFile={false}
                              maxCount={1}
                              allowFileTypes={['jpg', 'png', 'pdf']}
                              height="40px"
                              width="100%"
                              getSelectedFile={(value) => {
                                if (value.length == 0) {
                                  files.fileId3 = '';
                                }
                              }}
                            />
                          </td>
                        </tr>
                        {/* <tr>
                          <th>
                            <GLabelAtomVars align="left">
                              {t('com.label.00075', '__기타')}1
                            </GLabelAtomVars>
                          </th>
                          <td colSpan={3}>
                            <FileUploadVars
                              ref={fileDropzoneRef4}
                              atchFileGrIdInput={dtlFrgnAccount?.fileId4 ?? ''}
                              isMultipleFile={true}
                              maxCount={5}
                              allowFileTypes={['jpg', 'png', 'xlsx', 'pdf']}
                              height="40px"
                              width="100%"
                              getSelectedFile={(value) => {
                                if (value.length == 0) {
                                  files.fileId4 = '';
                                }
                              }}
                            />
                          </td>
                        </tr> */}
                        <tr>
                          <th>
                            <GLabelAtomVars align="left">
                              {t('com.label.00075', '__기타')}
                            </GLabelAtomVars>
                          </th>
                          <td colSpan={3}>
                            <FileUploadVars
                              ref={fileDropzoneRef5}
                              atchFileGrIdInput={dtlFrgnAccount?.fileId5 ?? ''}
                              isMultipleFile={true}
                              maxCount={5}
                              allowFileTypes={['jpg', 'png', 'xlsx', 'pdf']}
                              height="40px"
                              width="100%"
                              getSelectedFile={(value) => {
                                if (value.length == 0) {
                                  files.fileId5 = '';
                                }
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </GbasicTable>
                  </GbasicTableWrap>
                </GridItem>
              </GridBox>
            </DialogContent>
            <DialogActions className="popupBottom">
              <GButtonBox>
                <GButton
                  txt={t('com.label.prntPif', '__Payment Information Form 출력')}
                  sizes="medium"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={false}
                  hasTxt={true}
                  // chkImg={Delete} 프린터기 이미지 확인필요
                  onClick={handleClickPif}
                ></GButton>
                <GButton
                  txt={t('com.label.00036', '취소')}
                  sizes="medium"
                  onClick={handleClose}
                  btnColor="normal"
                  btnstyled="outline"
                  hasImg={false}
                  hasTxt={true}
                />
                <GButton
                  txt={t('com.label.00055', '저장')}
                  chkImg={Save}
                  sizes="medium"
                  onClick={handleOnSave}
                  btnstyled="contained"
                  btnColor="primary"
                  hasImg={true}
                  hasTxt={true}
                ></GButton>
              </GButtonBox>
            </DialogActions>
          </>
        )}
      </ModalLayout>
      <ForeignCurrencyBank
        open={bankNumberModalOpen}
        close={() => setBankNumberModalOpen(false)}
        isKrw={props.isKrw}
        save={(data) => {
          setChgAccnInfo((prev) => {
            return {
              ...prev,
              bankKeyCd: data.bankl,
              bankNm: data.banka,
              bankCtryCd: data.banks,
              swftCd: data.swift,
              bankBrchCd: data.brnch,
            };
          });
          // regAccnInfo.bankCd = data.bankCode;
        }}
        vendorInfo={props.vendorInfo}
      />
    </>
  );
};

const AreaAlignWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const ItemAlignWrap = styled.div`
  display: flex;
  gap: 4px;
`;

const Wrap = styled.div`
  display: flex;
  gap: 4px;
`;

export default DmsChgFrgnAcntModal;
