import dayjs from 'dayjs';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import Download from 'components/asset/images/Upload.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import searchImage from 'components/asset/images/Search.svg';

import { AgGridReact } from 'ag-grid-react';
import { useLoading } from 'components/process/Loading';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState } from 'react';
import { useMessageBar } from 'components/process/MessageBar';
import useSessionStore from 'stores/useSessionStore';

import GTabs from 'components/organism/GTabs';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import SubTitleBox from 'components/molecule/SubTitleBox';
import SearchMolecule from 'components/common/SearchMolecule';
import EmptyState from 'components/atom/emptyState';
import { GButton } from 'components/atom/button';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { TitleBtnBox } from 'components/molecule/ButtonArea';
import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';
import { CellClickedEvent } from 'ag-grid-community';

import { useOPaymentColDefs } from './hooks/OPaymentListDefs';
import { useOPaymentDetailColDefs } from './hooks/OPaymentListDetailDefs';
import { useONonPaymentColDefs } from './hooks/ONonPaymentDefs';
import { useONonPaymentDetailColDefs } from './hooks/ONonPaymentDetailDefs';
import { useOPaymentDueColDef } from './hooks/OPaymentDueDefs';
import {
  getONotePayableApi,
  getONotePayableDetailApi,
  getOPaymentDetailApi,
  getOPaymentListApi,
  getPaymentDueApi,
} from 'apis/vars/payment/PaymentApi';
import {
  getONotePayableDetailsExcelDownloadApi,
  getONotePayableExcelDownloadApi,
  getOPaymentDetailsExcelDownloadApi,
  getOPaymentDueListExcelDownloadApi,
  getOPaymentExcelDownloadApi,
} from 'apis/vars/payment/PaymentExcelApi';
import SubTitleBoxVars from 'components/vars/common/SubTitleBoxVars';

export interface PaymentSearchParams {
  dataInsDtmSt: string; // 조회기간 시작일자
  dataInsDtmEnd: string; // 조회기간 종료일자
  userId: string;
  downloadName: string;
}

interface PropsType {
  vendorInfo: VendorReqInfoVO;
}

// PaymentList
export interface OPaymentRes {
  payDt: string; // PaymentDate
  curr: string; // Currency
  payAmt: string; // Amount
  payBankCd: string; // Bank Cd
  payBankNm: string; // Bank Nm
  swift: string; // Swift Code
  accnNo: string; // Account
  swiftMsg: string; // Swift Message
  payDivNm: string; // Payment Method
  clearDmt: string; // Clearing Document #
  slipNo: string; // 전표번호(해외 상세조회 Request)
}

// 상세요청 값
export interface ODetailReq {
  accnNo: string;
  slipNo: string;
  payBankCd: string;
  payDt: string;
  userId: string;
  downloadName: string;
}

// PaymentListDetail
export interface OPaymentDetail {
  slipNo: string; // Document #
  prfDt: string; // Document Date
  payAmt: string; // Amount
  invcNo: string; // Invoice No.
  rfrnNo: string; // Reference No.
  chckNo: string; // Check No.
  mgrDeptNm: string; // Person in Charge Team
  purcOrd: string; // Purchase Order
}

// Note Payable(Issue)
export interface NotePayable {
  payDt: string; // Issue Date
  payAmt: string; // Issue Amount
  payBankCd: string; // Issue Bank Code
  payBankNm: string; // Issue Bank Name
  cnapsCd: string; // CNAPS Code
  accnNo: string; // Vender Bank Account
  drftNo: string; // Draft Number
  slipNo: string; // 전표번호(해외 상세조회 Request)
}

// Note Payable(Issue) Detail
export interface NotePayableDetail {
  slipNo: string; // Document #
  prfDt: string; // Document Date
  payAmt: string; // Amount
  invcNo: string; // Invoice No.
  rfrnNo: string; // Reference No.
  mgrDeptNm: string; // Person in Charge Team
  purcOrd: string; // Purchase Order
  dueDt: string; // Due Date
}

// Payment Due(Scheduled) List
export interface PaymentDue {
  slipNo: string; // Document #
  prfDt: string; // Document Date
  expDt: string; // Note Payable Due Date
  curr: string; // Currency
  payAmt: string; // Amount
  payDivNm: string; // Payment Method
  invcNo: string; // Invoice No.
  rfrnNo: string; // Reference No.
  mgrDeptNm: string; // Person in Charge Team
  purcOrd: string; // Purchase Order
}

const OPaymentSchedulePage = (props: PropsType) => {
  const { t } = useTranslation();
  const { userId, copCd, userCopCd, ctryCd, coCd } = useSessionStore();
  const gridApiRef = useRef<any>(null);
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const { defaultColDef, colDefs } = useOPaymentColDefs();
  const { defaultColDef1, colDefs1 } = useOPaymentDetailColDefs();
  const { defaultColDef3, colDefs3 } = useONonPaymentColDefs();
  const { defaultColDef4, colDefs4 } = useONonPaymentDetailColDefs();
  const { defaultColDef2, colDefs2 } = useOPaymentDueColDef();

  const [paymentList, setPaymentList] = useState<OPaymentRes[]>([]);
  const [paymentDetails, setPaymentDetails] = useState<OPaymentDetail[]>([]);
  const [notePayable, setNotePayable] = useState<NotePayable[]>([]);
  const [notePayableDetail, setNotePayableDetail] = useState<NotePayableDetail[]>([]);
  const [paymentDue, setPaymentDue] = useState<PaymentDue[]>([]);
  const [vendorInfo, setVendorInfo] = useState<VendorReqInfoVO>({
    ...props.vendorInfo,
  } as VendorReqInfoVO);

  const [detailReq, setDetailReq] = useState<ODetailReq>({
    accnNo: '',
    slipNo: '',
    payBankCd: '',
    payDt: '',
    userId: '',
    downloadName: '',
  });

  const [searchParams, setSearchParams] = useState<PaymentSearchParams>({
    // 0329 조회기간 디폴트 3개월 재수정(현업요청)
    // dataInsDtmSt: '20250101',
    // dataInsDtmEnd: '20250331',
    dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
    dataInsDtmEnd: dayjs().format('YYYYMMDD'),
    userId: userId,
    downloadName: '',
  });

  useEffect(() => {
    openLoading(true);
    getOPaymentListApi(searchParams).then((res) => {
      setPaymentList(res);
      openLoading(false);
      if (!res) {
        setPaymentList([]);
      }
    });
  }, []);

  // 그리드 행 선택 시 상세 api호출
  useEffect(() => {
    if (detailReq.accnNo) {
      openLoading(true);
      if (selectedTab === 1) {
        getOPaymentDetailApi(detailReq).then((res) => {
          setPaymentDetails(res);
          openLoading(false);
        });
      } else if (selectedTab === 3) {
        getONotePayableDetailApi(detailReq).then((res) => {
          setNotePayableDetail(res);
          openLoading(false);
        });
      }
    }
  }, [detailReq]);

  const handleCrtDateChange = (newDate, id) => {
    handleDateRangeChange(newDate, id, 'dataInsDtm', 'YYYY.MM.DD');
  };

  const handleDateRangeChange = (newDate, id, key, format) => {
    const newParams = {};
    if (id === 'end') {
      newParams[key + 'End'] = newDate.format(format);

      if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
        newParams[key + 'St'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    } else if (id === 'start') {
      newParams[key + 'St'] = newDate.format(format);

      if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
        newParams[key + 'End'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    }
  };

  const handleReset = () => {
    setSearchParams({
      // dataInsDtmSt: '20250101',
      // dataInsDtmEnd: '20250331',
      dataInsDtmSt: dayjs().subtract(3, 'month').format('YYYYMMDD'),
      dataInsDtmEnd: dayjs().format('YYYYMMDD'),
      userId: userId,
      downloadName: '',
    });
  };

  // 탭별 API 호출 분기처리
  const getDataByTap = (value, searchParams) => {
    if (value === 1) {
      getOPaymentListApi(searchParams).then((res) => {
        setPaymentList(res);
        openLoading(false);
        if (!res) {
          setPaymentList([]);
        }
      });
    } else if (value === 2) {
      getPaymentDueApi(searchParams).then((res) => {
        setPaymentDue(res);
        openLoading(false);
        if (!res) {
          setPaymentDue([]);
        }
      });
    } else if (value === 3) {
      getONotePayableApi(searchParams).then((res) => {
        setNotePayable(res);
        openLoading(false);
        if (!res) {
          setNotePayable([]);
        }
      });
    }
    setPaymentDetails([]);
    setNotePayableDetail([]);
  };

  const handleSearch = () => {
    if (
      searchParams.dataInsDtmSt === 'Invalid Date' ||
      searchParams.dataInsDtmEnd === 'Invalid Date'
    ) {
      return openCommonModal({
        content: t('com.label.00477', '__조회기간을 설정해주세요.'),
      });
    }

    // if (Number(searchParams.dataInsDtmEnd.substr(0, 4)) < 2025) {
    //   return openCommonModal({
    //     content: t('com.label.xxxxxx', '__2025.01.01 이후의 데이터만 조회 가능합니다'),
    //   });
    // }

    // if (Number(searchParams.dataInsDtmSt.substr(0, 4)) < 2025) {
    //   return openCommonModal({
    //     content: t('com.label.xxxxxx', '__2025.01.01 이후의 데이터만 조회 가능합니다'),
    //   });
    // }

    openLoading(true);
    const setdataInsDtmSt = dayjs(searchParams.dataInsDtmSt).format('YYYYMMDD');
    const setdataInsDtmEnd = dayjs(searchParams.dataInsDtmEnd).format('YYYYMMDD');
    const formattedReq = {
      dataInsDtmSt: setdataInsDtmSt,
      dataInsDtmEnd: setdataInsDtmEnd,
      userId: userId,
    };
    getDataByTap(selectedTab, formattedReq);
  };

  const handleTabChange = (e, value) => {
    setSelectedTab(value);
    openLoading(true);
    getDataByTap(value, searchParams);
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            {selectedTab === 2 ? (
              <SearchMolecule
                type="calendar"
                isRequired
                calendarReadonly
                labelTitle={t('com.label.00640', '__Payment Date')}
                calendarDouble={false}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarMaxWidth="128px"
                date={dayjs()}
              />
            ) : (
              <SearchMolecule
                type="calendar"
                isRequired
                labelTitle={t('com.label.00640', '__Payment Date')}
                calendarDouble={true}
                calendarFormat="YYYY.MM.DD"
                calendarViews={['year', 'month', 'day']}
                calendarMaxWidth="128px"
                startDate={dayjs(searchParams.dataInsDtmSt)}
                endDate={dayjs(searchParams.dataInsDtmEnd)}
                onChange={handleCrtDateChange}
                mindate={'2001.01.01'}
                maxdate={`${String(Number(new Date().getFullYear()) + 20)}.01.01`}
              />
            )}
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px" paddingTop="0px">
            {selectedTab !== 2 && (
              <GButtonBoxCum>
                <GButton
                  chkImg={RecycleB}
                  sizes="medium"
                  btnstyled="outline"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={false}
                  onClick={handleReset}
                ></GButton>
                <GButton
                  chkImg={searchImage}
                  txt={t('com.label.00620', '__View')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            )}
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>
      <GTabs
        flex={true}
        defaultValue={1}
        title={
          vendorInfo.land1 === 'CN'
            ? [
                t('com.label.00615', '__Payment List'),
                t('com.label.00617', '__Payment Due(Scheduled) List'),
                t('com.label.00616', '__Note Payable(Issue)'),
              ]
            : [
                t('com.label.00615', '__Payment List'),
                t('com.label.00617', '__Payment Due(Scheduled) List'),
              ]
        }
        onChange={handleTabChange}
      >
        <>
          <SubTitleBtnArea>
            <SubTitleBoxVars
              showSubTitle={true}
              content={t('com.label.00615', '__Payment List')}
              isInfo={true}
              pTxt={
                t(
                  'com.tooltip.pay01',
                  `__※ 원화 지급 기준
- 月 거래기준 합계액이 1천만원 이하인 경우 현금 결제 / 1천만원 초과인 경우 전자어음 결제\r\n
* 대기업의 경우 거래 합계액 불문 전자어음 결제
  - 전자어음 결제시 중소기업의 경우 60일 어음 / 대기업의 경우 90일 어음
  - 현금 결제일 : 매월 10일 / 20일 / 末일
  - 전자어음 결제일 : 매월 10일(전월 16~전월 말일자 계산서 및 전표처리 완료 분까지) 
    / 매월 20일(당월 1일~당월 15일자 계산서 및 전표처리 완료 분까지)`
                ) ?? ''
              }
              pPlace="3"
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentList?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getOPaymentExcelDownloadApi({
                    ...searchParams,
                    downloadName: t('com.label.00615', '__Payment List'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '12px' }}
            isSortable={false}
            scrollHeight="48px"
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={colDefs}
              rowData={paymentList}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
              onCellClicked={(e: CellClickedEvent) => {
                setDetailReq({
                  accnNo: e.data.accnNo,
                  slipNo: e.data.slipNo,
                  payBankCd: e.data.payBankCd,
                  payDt: e.data.payDt,
                  userId: userId,
                  downloadName: '',
                });
              }}
            ></AgGridReact>
          </GContentGrid>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={true}
              content={t('com.label.00618', '__Payment List Detail')}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentDetails?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getOPaymentDetailsExcelDownloadApi({
                    ...detailReq,
                    downloadName: t('com.label.00618', '__Payment List Detail'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef1}
              columnDefs={colDefs1}
              rowData={paymentDetails}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>

        <>
          <SubTitleBtnArea>
            <SubTitleBox
              showSubTitle={false}
              content={t('com.label.00617', '__Payment Due(Scheduled) List')}
              isVisibleInfo={true}
              infoContent={t(
                'com.tooltip.pay03',
                `__The following details may vary in amount and payment schedule depending on the registration status of the payment account, offsets, etc., based on the entries recorded in the ERP.`
              )}
              //  ${t(
              //   'com.tooltip.pay02',
              //   '__In the case of foreign currency payments, payment will only be made upon request for transfer by the LG Energy Solutions representative, even if the payment maturity date has arrived'
              // )}
            />
            <TitleBtnBox>
              <GButton
                txt={t('com.label.00666', '__다운로드')}
                chkImg={Download}
                btnstyled="outline"
                btnColor="normal"
                sizes="small"
                hasTxt={true}
                hasImg={true}
                disabled={paymentDue?.length > 0 ? false : true}
                onClick={() => {
                  openLoading(true);
                  getOPaymentDueListExcelDownloadApi({
                    ...searchParams,
                    downloadName: t('com.label.00617', '__Payment Due(Scheduled) List'),
                  })
                    .then((res) => {
                      openLoading(false);
                    })
                    .catch((error) => {
                      console.error('다운로드 중 에러 발생:', error);
                      openLoading(false);
                    });
                }}
              />
            </TitleBtnBox>
          </SubTitleBtnArea>
          <GContentGrid
            type="none"
            className="ag-theme-alpine"
            style={{ flex: 1, marginBottom: '8px' }}
            isSortable={false}
          >
            <AgGridReact
              defaultColDef={defaultColDef2}
              columnDefs={colDefs2}
              rowData={paymentDue}
              rowSelection={'single'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
        </>
        {vendorInfo.land1 === 'CN' && (
          <>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content={t('com.label.00719', '__Note Payable(Issue) List')}
              />
              <TitleBtnBox>
                <GButton
                  txt={t('com.label.00666', '__다운로드')}
                  chkImg={Download}
                  btnstyled="outline"
                  btnColor="normal"
                  sizes="small"
                  hasTxt={true}
                  hasImg={true}
                  disabled={notePayable?.length > 0 ? false : true}
                  onClick={() => {
                    openLoading(true);
                    getONotePayableExcelDownloadApi({
                      ...searchParams,
                      downloadName: t('com.label.00719', '__Note Payable(Issue) List'),
                    })
                      .then((res) => {
                        openLoading(false);
                      })
                      .catch((error) => {
                        console.error('다운로드 중 에러 발생:', error);
                        openLoading(false);
                      });
                  }}
                />
              </TitleBtnBox>
            </SubTitleBtnArea>
            <GContentGrid
              type="none"
              className="ag-theme-alpine"
              style={{ flex: 1, marginBottom: '12px' }}
              isSortable={false}
              scrollHeight="48px"
            >
              <AgGridReact
                defaultColDef={defaultColDef3}
                columnDefs={colDefs3}
                rowData={notePayable}
                rowSelection={'single'}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{ type: 'grid' }}
                onCellClicked={(e: CellClickedEvent) => {
                  setDetailReq({
                    accnNo: e.data.accnNo,
                    slipNo: e.data.slipNo,
                    payBankCd: e.data.payBankCd,
                    payDt: e.data.payDt,
                    userId: userId,
                    downloadName: '',
                  });
                }}
              ></AgGridReact>
            </GContentGrid>
            <SubTitleBtnArea>
              <SubTitleBox
                showSubTitle={true}
                content={t('com.label.00619', '__Note Payable(Issue) Detail')}
              />
              <TitleBtnBox>
                <GButton
                  txt={t('com.label.00666', '__다운로드')}
                  chkImg={Download}
                  btnstyled="outline"
                  btnColor="normal"
                  sizes="small"
                  hasTxt={true}
                  hasImg={true}
                  disabled={notePayableDetail?.length > 0 ? false : true}
                  onClick={() => {
                    openLoading(true);
                    getONotePayableDetailsExcelDownloadApi({
                      ...detailReq,
                      downloadName: t('com.label.00619', '__Note Payable(Issue) Detail'),
                    })
                      .then((res) => {
                        openLoading(false);
                      })
                      .catch((error) => {
                        console.error('다운로드 중 에러 발생:', error);
                        openLoading(false);
                      });
                  }}
                />
              </TitleBtnBox>
            </SubTitleBtnArea>
            <GContentGrid
              type="none"
              className="ag-theme-alpine"
              style={{ flex: 1, marginBottom: '8px' }}
              isSortable={false}
            >
              <AgGridReact
                defaultColDef={defaultColDef4}
                columnDefs={colDefs4}
                rowData={notePayableDetail}
                rowSelection={'single'}
                enableCellTextSelection
                onGridReady={(params) => (gridApiRef.current = params.api)}
                tooltipShowDelay={0}
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{ type: 'grid' }}
              ></AgGridReact>
            </GContentGrid>
          </>
        )}
      </GTabs>
    </>
  );
};

export default OPaymentSchedulePage;
