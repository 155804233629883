import { getPifDataApi } from 'apis/vars/account/PrintApi';
import Arrow from 'components/asset/images/arrow.jpg';
import LgEnsol from 'components/asset/images/LGEnergySolution.svg';
import { useEffect, useState } from 'react';
import { usePrintStore } from './usePrintStore';

export interface PifRes {
  vdcpCd: string;
  vdcpNm: string;
  vdcpCtyNm: string;
  vdcpDadr: string;
  vdcpMgrEncPhn: string;
  bzno: string;
  rpsnNm: string;
  vdcpMgrNm1: string;
  vdcpMgrEmal1: string;
  vdcpfundMgrNm: string;
  vdcpfundMgrEncPhn: string;
  vdcpfundMgrEmal: string;
  copNm: string;
  faxNo: string;
}

export interface PifBankInfo {
  dpstNm: string;
  currCd: string;
  swftCd: string;
  encAccnNo: string;
  bankNm: string;
  bankAddr: string;
  bankCtpl: string;
  bankEml: string;
}

const usePifPrint = () => {
  const [finalCopNm, setFinalCopNm] = useState<string>('');
  const [basicVendorInfo, setBasicVendorInfo] = useState<any>();
  const [bankInfo, setBankInfo] = useState<any>();
  const [pifMultiCopCd, setPifMultiCopCd] = useState<any>();
  const [fullNmMultiCopCd, setFullNmMultiCopCd] = useState<any>();

  const [wktDiv, setWktDiv] = useState<string>('');

  // 계좌추가정보 라벨값(Sort Code, ABA Routing, CNAPS Code, N/A ...)
  const [chgBankTpCdNm, setChgBankTpCdNm] = useState<any>();

  // 법인명만 api 호출
  useEffect(() => {
    getPifDataApi().then((res) => {
      setFinalCopNm(res.copNm);
    });
  }, []);

  useEffect(() => {
    if (pifMultiCopCd) {
      //0407 수정
      setFullNmMultiCopCd(pifMultiCopCd.map((item) => item.optValCtn2));
      if (wktDiv != 'N') {
        setFullNmMultiCopCd(null);
      }
    } else {
      setFullNmMultiCopCd(null);
    }
  }, [pifMultiCopCd]);

  useEffect(() => {
    if (wktDiv != 'N') {
      setFullNmMultiCopCd(null);
    } else {
      if (pifMultiCopCd) {
        //0407 수정
        setFullNmMultiCopCd(pifMultiCopCd.map((item) => item.optValCtn2));
      }
    }
  }, [wktDiv]);

  const handleClickPif = () => {
    let htmlContent = `
     <html lang="en"><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" type="text/css" href="design.css">
    <title>LG Energy Solution</title>
    <style>@charset "UTF-8";
html,
body {
  margin: 0 auto;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom : 99%;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
select,
textarea {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}

table,
th,
td,
input,
textarea,
select,
button {
  box-sizing: border-box;
  font-family: "맑은 고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
    AppleGothic, UnDotum, Arial;
  -webkit-text-size-adjust: none;
}

th,
td {
  font-size: 12px;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
}

table {
  width: 100%;
  border-spacing: 0;
  background-color: #ffffff;
  border-collapse: collapse;
  -webkit-text-size-adjust: none;
  border: 0;
  table-layout: fixed;
  box-sizing: border-box;
}

address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
  -webkit-text-size-adjust: none;
}
em {
  font-weight: inherit;
}
ol,
ul,
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  box-sizing: border-box;
}

legend,
caption {
  visibility: hidden;
  overflow: hidden;
  line-height: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: -99999px;
  font-size: 0;
}
img,
fieldset {
  border: 0 none;
}
span,
em,
strong {
  box-sizing: border-box;
}

a {
  color: #777;
  box-sizing: border-box;
}
a:link,
a:visited,
a:hover {
  color: #777;
  text-decoration: none;
}
input,
img {
  vertical-align: middle;
}

input,
button {
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
}
button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid #c9c9c9;
}
label {
  vertical-align: middle;
}

/* html5 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before,
*:after,
* {
  box-sizing: border-box;
}

/* page layout style (순서대로) */
.page {
  position: relative;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  border: 1px solid #ddd;
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    font-family: "맑은고딕", Dotum, "돋움", Gulim, "굴림", Tahoma, Verdana,
      AppleGothic, UnDotum, Arial;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .bold_text {
    font-weight: bold;
  }
}

/* 페이지 퍼블리싱 css */
.content_wrap {
  margin: 18px 15px;
}

.logo_wrap {
  text-align: right;

  img {
    width: 200px;
  }
}

.top_table {
  width: 100%;
  border: none;
  margin-bottom: 5px;
}

.title {
  font-size: 32px;
  text-align: center;
}

.sub_title {
  padding-top: 10px;
}

.text {
  font-size: 12px;
}

p.text {
  margin-top: 5px;
}

.bold_text {
  font-weight: bold !important;
}
tk

/* 콘텐츠 테이블 부분 */

.content_table {
  width: 100%;
  border: 1px solid #7f7f7f;
  font-size: 13px;
  border-collapse: collapse;
}

table[class="content_table"] td {
  border: 1px solid #7f7f7f;
  height: 30px !important;
  padding: 4px;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
}

table[class="content_table"] th {
  border: 1px solid #7f7f7f;
  padding: 4px;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
}

table[class="content_table"] .th_align_right {
  text-align: right !important;
}

.td_fog_text {
  color: #c4c4c4;
}

table[class="content_table"] .table_border_wrap {
  border: 2px solid black;
}

table[class="content_table"] .border_first {
  border-bottom: none;
}

table[class="content_table"] .boder_middle {
  border-top: none;
  border-bottom: none;
}

table[class="content_table"] .border_last {
  border-top: none;
}

.td_arrow_img {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.td_align {
  align-items: center;
  vertical-align: middle;
}

/* 입력란 부분 */
.write_wrap {
  margin-top: 8px;
}

.write_align {
  display: flex;
  gap: 13px;
  margin-top: 2px;
}

.write {
  border-top: 1px solid black;
  margin-top: 40px;
  padding-top: 2px;
}

.first {
  width: 350px;
}

.second {
  width: 150px;
}
</style>
  </head>
  <body>
    <div class="page">
      <div class="content_wrap">
        <div class="logo_wrap">
          <img src=${LgEnsol} alt="LGEnergySolutionLogo">
        </div>
        <table class="top_table bold_text">
          <tbody><tr>
            <td class="title">PAYMENT INFORMATION FORM</td>
          </tr>
          <tr>
            <td class="text bold_text sub_title">
              *All of the information below is required to set up payments.
            </td>
          </tr>
        </tbody></table>

        <table class="content_table">
          <colgroup>
            <col style="width: 200px">
            <col stlye="width: auto">
          </colgroup>
          <tbody>
            <tr>
              <th colspan="4" class="bold_text td_align">
                <img src=${Arrow} class="td_arrow_img">
                COMPANY INFORMATION
              </th>
            </tr>
            <tr>
              <th>Company / Customer Name</th>
              <td colspan="3">${basicVendorInfo?.vdcpErpNm ?? ''}</td>
            </tr>
            <tr>
              <th rowspan="2">Address:</th>
              <td colspan="3">${basicVendorInfo?.vdcpCtyNm ?? ''}</td>
            </tr>
            <tr>
              <td colspan="3">${basicVendorInfo?.vdcpDadr ?? ''}</td>
            </tr>
            <tr>
              <th>Telephone Number:</th>
              <td colspan="3">${basicVendorInfo?.vdcpMgrEncPhn ?? ''}</td>
            </tr>
            <tr>
              <th>Representative(CEO)</th>
              <td colspan="3">${basicVendorInfo?.rpsnNm ?? ''}</td>
            </tr>
            <tr> 
              <th>Corp Reg. No. / Tax ID</th> 
              <td>${basicVendorInfo?.copRegNo ?? ''}</td> 
              <th>Business Reg. No.</th> 
              <td>${basicVendorInfo?.bzno ?? ''}</td> 
            </tr>
            <tr>
              <th>Vat Reg. No</th>
              <td>${basicVendorInfo?.vatRegNo ?? ''}</td>
              <th>PAN/TIN No.</th>
              <td>${basicVendorInfo?.pan ?? ''}</td>
            </tr>
            <tr>
              <th>Sales dept. Contact info</th>
              <td colspan="3">
              ${basicVendorInfo?.vdcpMgrNm1 ?? ''}
              </td>
            </tr>
            <tr>
              <th>Telephone Number</th>
              <td colspan="3">${basicVendorInfo?.vdcpMgrEncPhn ?? ''}</td>
            </tr>
            <tr>
              <th>Fax Number</th>
              <td colspan="3">${basicVendorInfo?.vdcpMgrFaxNo ?? ''}</td>
            </tr>
            <tr>
              <th>E-mail Address</th>
              <td colspan="3">${basicVendorInfo?.vdcpMgrEmal1 ?? ''}</td>
            </tr>
            <tr class="table_border_wrap border_first">
              <th>Finance dept. Contact info</th>
              <td colspan="3">
                ${basicVendorInfo?.vdcpFundMgrNm ?? ''}
              </td>
            </tr>
            <tr class="table_border_wrap boder_middle">
              <th>Telephone Number</th>
              <td colspan="3">${basicVendorInfo?.vdcpFundMgrEncPhn ?? ''}</td>
            </tr>
            <tr class="table_border_wrap boder_middle">
              <th>Fax Number</th>
              <td colspan="3">${basicVendorInfo?.vdcpFundMgrFaxNo ?? ''}</td>
            </tr>
            <tr class="table_border_wrap border_last">
              <th>E-mail Address</th>
              <td colspan="3">${basicVendorInfo?.vdcpFundMgrEmal ?? ''}</td>
            </tr>
            <tr>
              <th colspan="4" class="bold_text">
                *All of the contact information must be company’s official
                numbers(not private email or phone/fax no.) <br>
                *Information in above bold box must be filled.
              </th>
            </tr>
            <tr>
              <th colspan="4" class="bold_text td_align">
                <img src=${Arrow} class="td_arrow_img">
                BANK INFORMATION
              </th>
            </tr>
            <tr>
              <th>Name on the Account</th>
              <td colspan="3">${bankInfo?.dpstNm ?? ''}</td>
            </tr>
            <tr>
              <th>Currency</th>
              <td colspan="3">${bankInfo?.currCd ?? ''}</td>
            </tr>
            <tr>
              <th>Swift Code</th>
              <td colspan="3">${bankInfo?.swftCd ?? ''}</td>
            </tr>
              <tr id="dynamicRow">
                <th>${chgBankTpCdNm ? chgBankTpCdNm : bankInfo?.bankNoTpCdNm ?? ''}</th>
                <td colspan="3">${bankInfo?.bankNo ?? ''}</td>
              </tr>
            <tr>
              <th>
                Account Number <br>
                (IBAN Code if available)
              </th>
              <td colspan="3">${
                bankInfo?.encAccnNo ? bankInfo?.encAccnNo : bankInfo?.ibanCd ?? ''
              }</td>
            </tr>
            <tr>
              <th>Name of Bank</th>
              <td colspan="3">${bankInfo?.bankNm ?? ''}</td>
            </tr>
            <tr>
              <th>Bank Address</th>
              <td colspan="3">${bankInfo?.bankAddr ?? ''}</td>
            </tr>
            <tr>
              <th>Bank Telephone Number:</th>
              <td colspan="3">${bankInfo?.bankCtpl ?? ''}</td>
            </tr>
            <tr>
              <th>Bank E-mail Address</th>
              <td colspan="3">${bankInfo?.bankEml ?? ''}</td>
            </tr>
          </tbody>
        </table>
        <p class="text">
          By providing the required information, the undersigned agrees to be
          paid by <span class="bold_text">${fullNmMultiCopCd ?? bankInfo?.copNm}</span> for
          all future invoices via Electronic Funds Trasfer to the bank account
          named above.
        </p>
        <div class="write_wrap">
          <div class="write_align">
            <div class="write first">Signature</div>
            <div class="write second">Date</div>
          </div>
          <div class="write_align">
            <div class="write first">Print Name/Title</div>
            <div class="write second">E-mail</div>
          </div>
        </div>
      </div>
    </div>
</body>
<script>
  window.onload = function() {
    window.print();

    setTimeout(() => {
        window.close();
      }, 0);
  };
</script>
</html>
    `;
    // bankNoTpCd가 '9'인경우(=bankNoTpCdNm가 N/A)는 해당tr 숨기기
    // 국내 외화의 경우 bankNoTpCd 없음 > tr 숨기기
    if (bankInfo?.bankNoTpCd === '9' || !bankInfo?.bankNoTpCd) {
      htmlContent = htmlContent.replace(
        '<tr id="dynamicRow">',
        '<tr id="dynamicRow" style="display: none;">'
      );
    }

    const width = Math.round(window.screen.width * 0.7);
    const height = Math.round(window.screen.height * 0.7);
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const newWindow = window.open(
      '',
      '_blank',
      `width=${width},height=${height},left=${left},top=${top},scrollbars=no,toolbar=no,location=no,status=no,resizable=no`
    );

    if (newWindow) {
      newWindow.document.write(htmlContent);
      newWindow.document.close();
    }
  };
  return {
    handleClickPif,
    setBankInfo,
    setBasicVendorInfo,
    setPifMultiCopCd,
    setChgBankTpCdNm,
    setWktDiv,
  };
};

export default usePifPrint;
