import { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import { GLabelAtom } from 'components/atom/label';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import InputField from 'components/atom/input';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SelectChangeEvent } from '@mui/material';
import GButtonLayout, { GButtonBox } from 'components/molecule/GbuttonLayout';
import GSelectMUIAtom from 'components/common/select/GSelectMUIAtom';
import { AlignBox } from 'components/organism/AlignBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import Save from 'components/asset/images/Confirm.svg';
import styled from '@emotion/styled';
import { CommonCode } from 'models/admin/CommonCode';
import {
  checkVendorApi,
  getMember,
  getSapCopCds,
  modifyCheckVendorApi,
  updateMemberInfoApi,
} from 'apis/vars/member/Member';
import GSelectMUIAtomVars from 'components/vars/common/GSelectMUIAtomVars';
import { useCommonModal } from 'hooks/useCommonModal';
import { ValidationBox } from 'components/validation/ValidationBox';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { useCommonCert } from 'hooks/useCommonCert';
import _ from 'lodash';
import VarsAutoInput from 'components/vars/signup/VarsAutoInput';
import { Employee } from 'models/admin/Employee';
import GridRadioVars2 from 'components/vars/common/GridRadioVars2';
import { useMessageBar } from 'components/process/MessageBar';
import { useLoading } from 'components/process/Loading';
import useMemberRegist from '../signup/hooks/useMemberRegist';
import { MemberEditVO } from '../manage/MemberDetailPage';
import { useLocation } from 'react-router-dom';
import useSessionStore from 'stores/useSessionStore';
import useMemberModify from './hooks/useMemberModify';
import { authLogOut } from 'apis/vars/login/Login';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';
import { getJwtCommonHqCodes } from 'apis/vars/common/Common';

const MyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const [validationErrMap, setValidationErrMap] = useState<Map<string, string>>(new Map());
  const { openCommonModal } = useCommonModal();
  const { userId } = useSessionStore();

  const { signedText, rValueBox, callCertModule } = useCommonCert();
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const { regSchema } = useMemberModify();

  // 초기 데이터
  const [initFormData, setInitFormData] = useState<MemberEditVO>({
    vdcpCd: '', //공급업체코드_로그인ID
    dmstFrgDivsCd: '', //국내해외구분
    vdcpMgrNm: '', //이름
    soprMgrCopCd: '', //엔솔법인코드
    soprMgrNm: '', //엔솔담당자
    vdcpNm: '', //업체명_직접입력
    vdcpErpNm: '', //업체명_ERP
    pwd: '', //패스워드
    vdcpMgrPhn: '', //연락처
    vdcpMgrEmal: '', //이메일
    idvlIfoClctCncCd: '', //개인정보의 수집 및 이용에 대한안내 동의
    dlvPayAgmnCncCd: '', //납품대금지급에관한약정서 동의
    chtrIdnClctCncCd: '', //고유식별정보와 수집 및 이용동의
    cfrmPwd: '', //패스워드 확인
    cfrmVdcpCd: true,
    vdcpMgrEmalId: '',
    vdcpMgrEmalDmn: '',
    vdcpMgrEmalCstmDmn: '',
    langCd: 'ko',
    managerInfo: [],

    // LG 에너지 솔루션 담당
    soprMgrEmal: '', // *이메일 등록
    soprMgrPhn: '', // *연락처 등록

    // 영업부서 담당자
    vdcpMgrNm1: '', // 이름
    vdcpMgrEncPhn: '', // 연락처
    vdcpMgrEmal1: '', // *이메일
    vdcpMgrEmal1Id: '',
    vdcpMgrEmal1Dmn: '',
    vdcpMgrEmal1CstmDmn: '',
    vdcpMgrAddEmal: '', // 추가 이메일
    vdcpMgrAddEmalId: '',
    vdcpMgrAddEmalDmn: '',
    vdcpMgrAddEmalCstmDmn: '',
    vdcpMgrFaxNo: '',

    // 자금부서 담당(해외)
    vdcpFundMgrNm: '', // 이름
    vdcpFundMgrEncPhn: '', // 연락처
    vdcpFundMgrEmal: '', // 이메일
    vdcpFundMgrEmalId: '',
    vdcpFundMgrEmalDmn: '',
    vdcpFundMgrEmalCstmDmn: '',
    vdcpFundMgrFaxNo: '',
    vdcpCtryCd: '',
  });
  // 회원가입
  const [signUpFormData, setSignUpFormData] = useState<MemberEditVO>({
    vdcpCd: '', //공급업체코드_로그인ID
    dmstFrgDivsCd: '', //국내해외구분
    vdcpMgrNm: '', //이름
    soprMgrCopCd: '', //엔솔법인코드
    soprMgrNm: '', //엔솔담당자
    vdcpNm: '', //업체명_직접입력
    vdcpErpNm: '', //업체명_ERP
    pwd: '', //패스워드
    vdcpMgrPhn: '', //연락처
    vdcpMgrEmal: '', //이메일
    idvlIfoClctCncCd: '', //개인정보의 수집 및 이용에 대한안내 동의
    dlvPayAgmnCncCd: '', //납품대금지급에관한약정서 동의
    chtrIdnClctCncCd: '', //고유식별정보와 수집 및 이용동의
    cfrmPwd: '', //패스워드 확인
    cfrmVdcpCd: true,
    vdcpMgrEmalId: '',
    vdcpMgrEmalDmn: '',
    vdcpMgrEmalCstmDmn: '',
    langCd: 'ko',
    managerInfo: [],

    // LG 에너지 솔루션 담당
    soprMgrEmal: '', // *이메일 등록
    soprMgrPhn: '', // *연락처 등록

    // 영업부서 담당자
    vdcpMgrNm1: '', // 이름
    vdcpMgrEncPhn: '', // 연락처
    vdcpMgrEmal1: '', // *이메일
    vdcpMgrEmal1Id: '',
    vdcpMgrEmal1Dmn: '',
    vdcpMgrEmal1CstmDmn: '',
    vdcpMgrAddEmal: '', // 추가 이메일
    vdcpMgrAddEmalId: '',
    vdcpMgrAddEmalDmn: '',
    vdcpMgrAddEmalCstmDmn: '',
    vdcpMgrFaxNo: '',

    // 자금부서 담당(해외)
    vdcpFundMgrNm: '', // 이름
    vdcpFundMgrEncPhn: '', // 연락처
    vdcpFundMgrEmal: '', // 이메일
    vdcpFundMgrEmalId: '',
    vdcpFundMgrEmalDmn: '',
    vdcpFundMgrEmalCstmDmn: '',
    vdcpFundMgrFaxNo: '',
    vdcpCtryCd: '',
  });

  //TODO 법인 (개발시 공통코드)
  const [coCdList, setCoCdList] = useState<CommonCode[]>([]);
  // 이메일 도메인
  const [emlDmnCdList, setEmlDmnCdList] = useState<CommonCode[]>([]);
  // 담당자 검색 팝업
  const [managerSrchModalOpen, setManagerSrchModalOpen] = useState<boolean>(false);
  // 선택된 담당자 리스트
  const [selectedManagerData, setSelectedManagerData] = useState<Employee[]>([]);
  // 한국/ 한국외
  const [dmstFrgOptionList, setDmstFrgOptionList] = useState<CommonCode[]>([]);
  const [member, setMember] = useState<MemberEditVO>();
  // 선택된 담당자 userId
  const [managerUserId, setManagerUserId] = useState<string>('');

  // 초기 법인명
  const [initCoCd, setInitCoCd] = useState<string>('');
  // 초기 담당자
  const [initManager, setInitManager] = useState<Employee[]>([]);

  const menuContext = useContext<MenuContextType>(MenuContext);

  const [getHqCmncd, setHqCmncd] = useState<string>();

  /**
   * 국내/ 해외 여부 Radio 체인지 이벤트
   * @param e
   */
  const handleChangeFrgDivsCd = (e) => {
    setSignUpFormData((prev) => {
      return { ...prev, dmstFrgDivsCd: e.target.value };
    });
  };

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeForLoginForm = (e) => {
    const numberInputs = [
      'vdcpMgrPhn',
      'vdcpMgrEncPhn',
      'vdcpFundMgrEncPhn',
      'vdcpMgrFaxNo',
      'vdcpFundMgrFaxNo',
    ];

    if (numberInputs.includes(e.target.id)) {
      e.target.value = e.target.value.replace(/[^\d]/g, '', '');
    }
    setSignUpFormData((prev) => {
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  /**
   * validation
   * @param object
   */
  const checkValidate = async (schema: any, targetData: any) => {
    try {
      setValidationErrMap(new Map());
      await schema.validate(targetData, { abortEarly: false });
      return true;
    } catch (e: any) {
      const errMap = new Map();
      e.inner?.map((err) => {
        errMap.set(err.path, err.message);
      });
      setValidationErrMap(errMap);
      return;
    }
  };

  /**
   * 이메일 생성
   */
  const setEmalAddr = () => {
    const getEmail = (id, dmn) => {
      return id && dmn ? `${id}@${dmn}` : '';
    };

    const tmpVdcpMgrEmal = getEmail(signUpFormData.vdcpMgrEmalId, signUpFormData.vdcpMgrEmalDmn);
    const tmpVdcpMgrEmal1 = getEmail(signUpFormData.vdcpMgrEmal1Id, signUpFormData.vdcpMgrEmal1Dmn);
    const tmpVdcpMgrAddEmal = getEmail(
      signUpFormData.vdcpMgrAddEmalId,
      signUpFormData.vdcpMgrAddEmalDmn
    );
    const tmpVdcpFundMgrEmal = getEmail(
      signUpFormData.vdcpFundMgrEmalId,
      signUpFormData.vdcpFundMgrEmalDmn
    );

    setSignUpFormData((prev) => ({
      ...prev,
      vdcpMgrEmal: tmpVdcpMgrEmal ?? '',
      vdcpMgrEmal1: tmpVdcpMgrEmal1 ?? '',
      vdcpMgrAddEmal: tmpVdcpMgrAddEmal ?? '',
      vdcpFundMgrEmal: tmpVdcpFundMgrEmal ?? '',
    }));

    setInitFormData((prev) => ({
      ...prev,
      vdcpMgrEmal: tmpVdcpMgrEmal ?? '',
      vdcpMgrEmal1: tmpVdcpMgrEmal1 ?? '',
      vdcpMgrAddEmal: tmpVdcpMgrAddEmal ?? '',
      vdcpFundMgrEmal: tmpVdcpFundMgrEmal ?? '',
    }));
  };

  /**
   * VendorID 인증 (SAP_IF)
   */
  const validateVenderId = async () => {
    //TODO 임시 패스
    if (!signUpFormData.vdcpCd) {
      openMessageBar('warning', t('com.label.00016', 'Vendor ID를 입력하세요'));
      return;
    }
    if (!signUpFormData.soprMgrCopCd) {
      openMessageBar('warning', t('com.label.00612', '법인명을 선택하세요'));
      return;
    }
    await modifyCheckVendorApi(
      signUpFormData.vdcpCd,
      signUpFormData.soprMgrCopCd,
      signUpFormData.dmstFrgDivsCd
    ).then((res) => {
      if (res.successOrNot === 'Y') {
        if (res.data.sapValidYn == 'N') {
          openLoading(false);
          openMessageBar(
            'warning',
            t('com.label.00717', '선택한 법인에 해당 Vendor ID가 존재하지 않습니다.')
          );
          return;
        }
        setSignUpFormData((prev) => {
          return { ...signUpFormData, cfrmVdcpCd: true };
        });
        onEditForm();
      }
    });
  };

  /**
   * 유효성 체크
   * @returns
   */
  const formValid = () => {
    // 이메일 형식
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emails = [
      signUpFormData.vdcpMgrEmal,
      signUpFormData.vdcpMgrEmal1,
      signUpFormData.vdcpMgrAddEmal,
      signUpFormData.vdcpFundMgrEmal,
    ];

    const invalidEmails = emails.filter((email) => email && !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      openMessageBar('warning', t('com.label.00230', '__이메일 형식이 올바르지 않습니다.'));
      openLoading(false);
      return false;
    }
    return true;
  };

  /**
   * 데이터 가공
   * @param pData
   */
  const convertVenderInfo = (pData) => {
    if (!pData) return;

    // 담당자 이메일
    pData.vdcpMgrEmalId = pData.vdcpMgrEmal?.split('@')[0];
    pData.vdcpMgrEmalDmn = pData.vdcpMgrEmal?.split('@')[1];

    // 영업부서 담당자 이메일
    pData.vdcpMgrEmal1Id = pData.vdcpMgrEmal1?.split('@')[0];
    pData.vdcpMgrEmal1Dmn = pData.vdcpMgrEmal1?.split('@')[1];

    // 영업부서 담당자 추가이메일
    pData.vdcpMgrAddEmalId = pData.vdcpMgrAddEmal?.split('@')[0];
    pData.vdcpMgrAddEmalDmn = pData.vdcpMgrAddEmal?.split('@')[1];

    // 자금부서 담당자 이메일
    pData.vdcpFundMgrEmalId = pData.vdcpFundMgrEmal?.split('@')[0];
    pData.vdcpFundMgrEmalDmn = pData.vdcpFundMgrEmal?.split('@')[1];

    setSignUpFormData((prev) => {
      return { ...prev, ...pData };
    });
    setInitFormData((prev) => {
      return { ...prev, ...pData };
    });
  };

  useEffect(() => {
    convertVenderInfo(member);
  }, [emlDmnCdList, member]);

  const getDmstFrgDivCds = useCallback(() => {
    getCommonCodes('DMST_FRG_DIVS_CD').then((result: CommonCode[]) => {
      setDmstFrgOptionList(result);
    });
  }, []);

  const cmnDataInit = async () => {
    //법인명 가져오기
    await getSapCopCds().then((result: CommonCode[]) => {
      setCoCdList(result);
    });

    // 한국, 한국외 가져오기
    await getDmstFrgDivCds();
  };

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  useEffect(() => {
    openLoading(true);
    cmnDataInit().then(() => {
      // 회원 조회
      if (userId) {
        getMember(userId)
          .then((data) => {
            if (data) {
              const tmpData = data as MemberEditVO;
              setMember(tmpData);
              convertVenderInfo(tmpData);
              // managerInfo가 null, undefined 모두 체크
              const managerInfo = Array.isArray(tmpData.managerInfo) ? tmpData.managerInfo : [];
              setSelectedManagerData(managerInfo);
              setInitManager(managerInfo);

              // managerInfo 배열이 있고, 첫 번째 항목이 존재하는지 확인
              const firstManager = managerInfo.length > 0 ? managerInfo[0] : null;
              setManagerUserId(firstManager && firstManager.userId ? firstManager.userId : '');

              setInitCoCd(tmpData.soprMgrCopCd);
              openLoading(false);
            }
          })
          .catch(handleError);
      }
    });
  }, []);

  // 수정로직
  const onEditForm = async () => {
    const validResult = await checkValidate(regSchema, signUpFormData);
    if (!validResult) {
      openLoading(false);
      return;
    }
    // 유효성 체크 (이메일 유효성)
    if (formValid() === true) {
      updateMemberInfoApi(signUpFormData).then((result) => {
        if (result === true) {
          openLoading(false);
          openCommonModal({
            modalType: 'alert',
            content: t('com.label.00173', '__수정되었습니다.'),
            yesCallback: () => {
              menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '000139' });
              navigate('/account-regist', { replace: true });
              // authLogOut();
            },
          });
        }
      });
    }
  };

  useEffect(() => {
    // 화면 초기 로딩될떄 동작
    const response = getJwtCommonHqCodes('COP_CD');
    response.then((resp) => {
      setHqCmncd(resp.data?.cmnCd);
    });
  }, []);

  // 법인명 C100일 경우 DO 그 외 OV
  const handleChangeCopCd = (e) => {
    const selectedCopCd = e.target.value;
    if (selectedCopCd === getHqCmncd) {
      setSignUpFormData((prev) => {
        return { ...prev, dmstFrgDivsCd: 'DO', soprMgrCopCd: selectedCopCd };
      });
    } else {
      setSignUpFormData((prev) => {
        return { ...prev, dmstFrgDivsCd: 'OV', soprMgrCopCd: selectedCopCd };
      });
    }
  };

  // 이메일 도메인 셀렉트
  const emalKeys = Object.keys(signUpFormData).filter((key) => key.includes('Emal'));
  useEffect(() => {
    setEmalAddr();
  }, [emalKeys.map((key) => signUpFormData[key])]);

  const handleOnReset = () => {
    setSignUpFormData(initFormData);
    setSelectedManagerData(initManager);
  };

  /**
   * 저장(수정) 버튼 이벤트
   */
  const handleOnSave = async () => {
    console.log('저장데이터 :::', signUpFormData);
    try {
      openLoading(true);
      if (initCoCd != signUpFormData.soprMgrCopCd) {
        await validateVenderId();
      } else {
        onEditForm();
      }
    } catch (e: any) {
      return;
    }
  };

  return (
    <>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">Vendor ID</GLabelAtom>
              </th>
              <td>{signUpFormData.vdcpCd}</td>
              {/* <th>
                <GLabelAtom align="left">{t('com.label.00117', '구분')}</GLabelAtom>
              </th>
              <td>{signUpFormData.dmstFrgDivsCdNm}</td> */}
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00443', '__회원 가입자 이름')}
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  id="vdcpMgrNm"
                  width="350px"
                  placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                  status={
                    validationErrMap.get('vdcpMgrNm') && signUpFormData.vdcpMgrNm.trim() === ''
                      ? 'error'
                      : 'default'
                  }
                  helperText={
                    validationErrMap.get('vdcpMgrNm') && signUpFormData.vdcpMgrNm.trim() === ''
                      ? validationErrMap.get('vdcpMgrNm')
                      : undefined
                  }
                  onChange={handleChangeForLoginForm}
                  value={signUpFormData.vdcpMgrNm}
                  maxLength={25}
                />
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00017', '__업체명')}
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  id="vdcpNm"
                  width="350px"
                  placeholder={t2('com.label.00018', '__업체명을 입력하세요')}
                  status={
                    validationErrMap.get('vdcpNm') && signUpFormData.vdcpNm.trim() === ''
                      ? 'error'
                      : 'default'
                  }
                  helperText={
                    validationErrMap.get('vdcpNm') && signUpFormData.vdcpNm.trim() === ''
                      ? validationErrMap.get('vdcpNm')
                      : undefined
                  }
                  maxLength={100}
                  onChange={handleChangeForLoginForm}
                  value={signUpFormData.vdcpNm}
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00610', '__법인명')}
                </GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('soprMgrCopCd')}>
                  <GSelectMUIAtom
                    selectWidth="350px"
                    option={coCdList}
                    readOnly={false}
                    codeKey="cmnCd"
                    codeName="cmnCdDesc"
                    placeholder={t2('com.label.00023', '__선택')}
                    value={signUpFormData.soprMgrCopCd}
                    handleChange={handleChangeCopCd}
                  />
                </ValidationBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00091', '__전화번호')}
                </GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpMgrPhn"
                    width="350px"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    status={
                      validationErrMap.get('vdcpMgrPhn') && signUpFormData.vdcpMgrPhn.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpMgrPhn') && signUpFormData.vdcpMgrPhn.trim() === ''
                        ? validationErrMap.get('vdcpMgrPhn')
                        : undefined
                    }
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={30}
                    value={signUpFormData.vdcpMgrPhn}
                  />
                </AlignBox>
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00090', '__이메일')}
                </GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('vdcpMgrEmal')}>
                  <AlignBox>
                    <InputField
                      id="vdcpMgrEmalId"
                      width="165px"
                      disabled
                      readOnly={true}
                      status="disabled"
                      placeholder={t2('com.label.00025', '__이메일아이디')}
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      value={signUpFormData.vdcpMgrEmalId}
                      maxLength={100}
                    />
                    @
                    <InputField
                      id="vdcpMgrEmalDmn"
                      value={signUpFormData.vdcpMgrEmalDmn}
                      status="default"
                      placeholder={t2('com.label.00026', '__직접입력')}
                      width="165px"
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={100}
                    />
                  </AlignBox>
                </ValidationBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00015', '__LG에너지솔루션 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00095', '__담당자')}
                </GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('soprMgrNm')}>
                  <VarsAutoInput
                    id="signup"
                    width="350px"
                    openModal={managerSrchModalOpen}
                    setOpenModal={setManagerSrchModalOpen}
                    signUpFormData={signUpFormData}
                    setSignUpFormData={setSignUpFormData}
                    selectedManagerData={selectedManagerData}
                    setSelectedManagerData={setSelectedManagerData}
                    onClick={() => setManagerSrchModalOpen(true)}
                    status={
                      validationErrMap.get('soprMgrNm') && signUpFormData.soprMgrNm.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                  />
                </ValidationBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    disabled
                    readOnly={true}
                    width="350px"
                    status="disabled"
                    // value={
                    //   selectedManagerData.length > 0 ? selectedManagerData[0].emlSvrDmnIfoNm : ''
                    // }
                    value={
                      selectedManagerData.length > 0 && selectedManagerData[0]?.emlSvrDmnIfoNm
                        ? selectedManagerData[0].emlSvrDmnIfoNm
                        : ''
                    }
                  />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00089', '__영업부서 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00012', '__이름')}
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  id="vdcpMgrNm1"
                  width="350px"
                  placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                  onChange={handleChangeForLoginForm}
                  value={signUpFormData.vdcpMgrNm1}
                  status={
                    validationErrMap.get('vdcpMgrNm1') && signUpFormData.vdcpMgrNm1.trim() === ''
                      ? 'error'
                      : 'default'
                  }
                  helperText={
                    validationErrMap.get('vdcpMgrNm1') && signUpFormData.vdcpMgrNm1.trim() === ''
                      ? validationErrMap.get('vdcpMgrNm1')
                      : undefined
                  }
                  maxLength={100}
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00091', '__전화번호')}
                </GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpMgrEncPhn"
                    width="350px"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    value={signUpFormData.vdcpMgrEncPhn}
                    status={
                      validationErrMap.get('vdcpMgrEncPhn') &&
                      signUpFormData.vdcpMgrEncPhn.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpMgrEncPhn') &&
                      signUpFormData.vdcpMgrEncPhn.trim() === ''
                        ? validationErrMap.get('vdcpMgrEncPhn')
                        : undefined
                    }
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={100}
                  />
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00090', '__이메일')}
                </GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('vdcpMgrEmal1')}>
                  <AlignBox>
                    <InputField
                      id="vdcpMgrEmal1Id"
                      width="165px"
                      status="default"
                      placeholder={t2('com.label.00025', '__이메일아이디')}
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      value={signUpFormData.vdcpMgrEmal1Id}
                      maxLength={100}
                    />
                    @
                    <InputField
                      id="vdcpMgrEmal1Dmn"
                      status="default"
                      placeholder={t2('com.label.00026', '__직접입력')}
                      value={signUpFormData.vdcpMgrEmal1Dmn}
                      width="165px"
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={100}
                    />
                  </AlignBox>
                </ValidationBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpMgrFaxNo"
                    width="350px"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    status="default"
                    value={signUpFormData.vdcpMgrFaxNo}
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={15}
                  />
                </AlignBox>
              </td>
              {/* <th>
                <GLabelAtom align="left">{t('com.label.00092', '__추가 이메일')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpMgrAddEmalId"
                    width="165px"
                    status="default"
                    placeholder={t2('com.label.00025', '__이메일아이디')}
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    value={signUpFormData.vdcpMgrAddEmalId}
                    maxLength={100}
                  />
                  @
                  <InputField
                    id="vdcpMgrAddEmalDmn"
                    status="default"
                    placeholder={t2('com.label.00026', '__직접입력')}
                    value={signUpFormData.vdcpMgrAddEmalDmn}
                    width="165px"
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={100}
                  />
                </AlignBox>
              </td> */}
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00093', '__자금부서 담당자')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true} style={{ marginBottom: '0px' }}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00012', '__이름')}
                </GLabelAtom>
              </th>
              <td>
                <InputField
                  id="vdcpFundMgrNm"
                  width="350px"
                  placeholder={t2('com.label.00013', '__이름을 입력하세요')}
                  onChange={handleChangeForLoginForm}
                  value={signUpFormData.vdcpFundMgrNm}
                  status={
                    validationErrMap.get('vdcpFundMgrNm') &&
                    signUpFormData.vdcpFundMgrNm.trim() === ''
                      ? 'error'
                      : 'default'
                  }
                  helperText={
                    validationErrMap.get('vdcpFundMgrNm') &&
                    signUpFormData.vdcpFundMgrNm.trim() === ''
                      ? validationErrMap.get('vdcpFundMgrNm')
                      : undefined
                  }
                  maxLength={100}
                />
              </td>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00091', '__전화번호')}
                </GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpFundMgrEncPhn"
                    width="350px"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    status={
                      validationErrMap.get('vdcpFundMgrEncPhn') &&
                      signUpFormData.vdcpFundMgrEncPhn.trim() === ''
                        ? 'error'
                        : 'default'
                    }
                    helperText={
                      validationErrMap.get('vdcpFundMgrEncPhn') &&
                      signUpFormData.vdcpFundMgrEncPhn.trim() === ''
                        ? validationErrMap.get('vdcpFundMgrEncPhn')
                        : undefined
                    }
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    value={signUpFormData.vdcpFundMgrEncPhn}
                    maxLength={100}
                  />
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left" isRequired={true}>
                  {t('com.label.00090', '__이메일')}
                </GLabelAtom>
              </th>
              <td>
                <ValidationBox msg={validationErrMap.get('vdcpFundMgrEmal')}>
                  <AlignBox>
                    <InputField
                      id="vdcpFundMgrEmalId"
                      width="165px"
                      status="default"
                      placeholder={t2('com.label.00025', '__이메일아이디')}
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      value={signUpFormData.vdcpFundMgrEmalId}
                      maxLength={100}
                    />
                    @
                    <InputField
                      id="vdcpFundMgrEmalDmn"
                      status="default"
                      placeholder={t2('com.label.00026', '__직접입력')}
                      value={signUpFormData.vdcpFundMgrEmalDmn}
                      width="165px"
                      onChange={(e) => {
                        handleChangeForLoginForm(e);
                      }}
                      maxLength={100}
                    />
                  </AlignBox>
                </ValidationBox>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>
                  <InputField
                    id="vdcpFundMgrFaxNo"
                    width="350px"
                    placeholder={t2('com.label.00024', '__-없이 숫자만 입력')}
                    status="default"
                    value={signUpFormData.vdcpFundMgrFaxNo}
                    onChange={(e) => {
                      handleChangeForLoginForm(e);
                    }}
                    maxLength={15}
                  />
                </AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <GButtonLayout marginBottom="8px">
        <GButtonBox>
          <GButton
            txt={t('com.label.00505', '초기화')}
            sizes="medium"
            onClick={handleOnReset}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            chkImg={Save}
            txt={t('com.label.00055', '__저장')}
            sizes="medium"
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
            onClick={handleOnSave}
          />
        </GButtonBox>
      </GButtonLayout>
    </>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentItem = styled(ContentWrap)`
  margin-top: 12px;
`;

export default MyPage;
