import { switchCase } from '@babel/types';
import { CommonCode } from 'models/admin/CommonCode';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { LoginInfo } from 'models/vars/login/LoginInfo';
import { MemberVO } from 'models/vars/member/MemberVO';
import { MemberResetVO } from 'pages/vars/login/MemberResetModal';
import { callApi } from 'utils/ApiUtil';
import { callJwtApi } from 'utils/JwtApiUtil';
import { MemberVdcpCdMapVO } from 'models/vars/member/MemberVdcpCdMapVO';

const PREFIX_URI = '/api/member';

/**
 * 단일 사용자 조회
 * @param userId
 * @returns
 */
export const getMember = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse<MemberVO> = await callApi(request);
  return response.successOrNot === 'Y' ? (response.data as MemberVO) : null;
};

/**
 * 사용자 수정
 * @param userId
 * @returns
 */
export const updateMember = async (searchParams: MemberVO) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `${PREFIX_URI}/${searchParams.vdcpCd}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...searchParams }),
  };
  const response: CommonResponse<MemberVO> = await callApi(request);
  return response.successOrNot === 'Y' ? (response.data as MemberVO) : null;
};

/**
 * 사용자 목록 조회
 * @param searchParams
 * @returns
 */
export const getMemberList = async (searchParams: MemberVO) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/member`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...searchParams }),
  };
  const response: CommonResponse<MemberVO[]> = await callApi(request);
  return response.successOrNot === 'Y' ? (response.data as MemberVO[]) : null;
};

/**
 * 사용자 가입 승인/반려 처리
 * @param searchParams
 * @returns
 */
export const confirmMemberSignUp = async (searchParams: MemberVO) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/member/regist-memberjoin`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: searchParams,
  };
  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 법인코드 조회
 * @returns
 */
export const getSapCopCds = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/common/sap-if-cop`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    // queryParams: new URLSearchParams({ cmnGrCd: cmnGrCd }),
  };
  const response: CommonResponse<CommonCode[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as CommonCode[];
};

//TODO 수정
export const getNationPhoneCds = async () => {
  return [
    {
      cmnGrCd: 'NTN_PHN_NO',
      cmnCd: '+82',
      cmnCdNm: '+82',
      cmnCdDesc: '한국',
      upprCmnCd: null,
      sortOrd: '1',
      useYn: 'Y',
      msgCtn: 'com.code.NTN_PHN_NO.82',
      optValCtn1: null,
      optValCtn2: 'C',
      optValCtn3: 'GMT000000009',
      optValCtn4: null,
      optValCtn5: null,
      optValCtn6: null,
      optValCtn7: null,
      optValCtn8: null,
      optValCtn9: null,
      optValCtn10: null,
      optValCtn11: null,
      optValCtn12: null,
      optValCtn13: null,
      optValCtn14: null,
      optValCtn15: null,
    },
    {
      cmnGrCd: 'NTN_PHN_NO',
      cmnCd: '+86',
      cmnCdNm: '+86',
      cmnCdDesc: '중국',
      upprCmnCd: null,
      sortOrd: '2',
      useYn: 'Y',
      msgCtn: 'com.code.NTN_PHN_NO.86',
      optValCtn1: null,
      optValCtn2: 'C',
      optValCtn3: 'GMT000000009',
      optValCtn4: null,
      optValCtn5: null,
      optValCtn6: null,
      optValCtn7: null,
      optValCtn8: null,
      optValCtn9: null,
      optValCtn10: null,
      optValCtn11: null,
      optValCtn12: null,
      optValCtn13: null,
      optValCtn14: null,
      optValCtn15: null,
    },
    {
      cmnGrCd: 'NTN_PHN_NO',
      cmnCd: '+44',
      cmnCdNm: '+44',
      cmnCdDesc: '영국',
      upprCmnCd: null,
      sortOrd: '3',
      useYn: 'Y',
      msgCtn: 'com.code.NTN_PHN_NO.44',
      optValCtn1: null,
      optValCtn2: 'C',
      optValCtn3: 'GMT000000009',
      optValCtn4: null,
      optValCtn5: null,
      optValCtn6: null,
      optValCtn7: null,
      optValCtn8: null,
      optValCtn9: null,
      optValCtn10: null,
      optValCtn11: null,
      optValCtn12: null,
      optValCtn13: null,
      optValCtn14: null,
      optValCtn15: null,
    },
  ];
};

//TODO 수정
export const getLocalPhoneCds = async (ntnPhnNo: string) => {
  switch (ntnPhnNo) {
    case '+86':
      return [
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '0861',
          cmnCdNm: '0861',
          sortOrd: '1',
        },
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '0862',
          cmnCdNm: '0862',
          sortOrd: '2',
        },
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '0863',
          cmnCdNm: '0863',
          sortOrd: '3',
        },
      ];
    case '+44':
      return [
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '0441',
          cmnCdNm: '0441',
          sortOrd: '1',
        },
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '0442',
          cmnCdNm: '0442',
          sortOrd: '2',
        },
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '0443',
          cmnCdNm: '0443',
          sortOrd: '3',
        },
      ];
    default:
      return [
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '02',
          cmnCdNm: '02',
          sortOrd: '1',
        },
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '031',
          cmnCdNm: '031',
          sortOrd: '2',
        },
        {
          cmnGrCd: 'LCL_PHN_NO',
          cmnCd: '032',
          cmnCdNm: '032',
          sortOrd: '3',
        },
      ];
  }
};

//TODO 수정
export const getEmailDomainCds = async () => {
  return [
    {
      cmnGrCd: 'EMAL_DMN_CD',
      cmnCd: 'lgensol.com',
      cmnCdNm: 'lgensol.com',
      sortOrd: '1',
    },
    {
      cmnGrCd: 'EMAL_DMN_CD',
      cmnCd: 'naver.com',
      cmnCdNm: 'naver.com',
      sortOrd: '2',
    },
    {
      cmnGrCd: 'EMAL_DMN_CD',
      cmnCd: 'gmail.com',
      cmnCdNm: 'gmail.com',
      sortOrd: '3',
    },
    {
      cmnGrCd: 'EMAL_DMN_CD',
      cmnCd: 'custom',
      cmnCdNm: '직접입력',
      sortOrd: '4',
    },
  ];
};

/**
 * 회원 가입시 벤더ID 체크
 * @param userId
 * @param copCd
 * @param dmstFrgDivsCd
 * @returns
 */
export const checkVendorApi = async (userId, copCd, dmstFrgDivsCd) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/member/valid-vendorinfo`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      userId: userId,
      copCd: copCd,
      dmstFrgDivsCd: dmstFrgDivsCd,
    }),
  };
  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 구매처 정보 수정 시 벤더ID 체크
 * @param userId
 * @param copCd
 * @param dmstFrgDivsCd
 * @returns
 */
export const modifyCheckVendorApi = async (userId, copCd, dmstFrgDivsCd) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/member/modify-valid-vendorinfo`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({
      userId: userId,
      copCd: copCd,
      dmstFrgDivsCd: dmstFrgDivsCd,
    }),
  };
  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 사용자 회원가입
 * @param param
 * @returns
 */
export const signUpMember = async (param: MemberVO) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `${PREFIX_URI}/sign-up`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callApi(request);

  return response;
};

/**
 * 사용자 정보 수정
 * @param userId
 * @returns
 */
export const modifyUserInfoApi = async (param: MemberVO) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/member/modify`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: { ...param },
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// 사용자 삭제
export const deleteUserInfoApi = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/member?userId=${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// 회원 정보 초기화 > VendorID 확인
export const checkVendorIdApi = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/api/member/valid-vendor?userId=${userId}`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.data === 'Y' ? true : false;
};

// 회원 정보 초기화 > 초기화
export const resetMemberInfoApi = async (resetFormData: MemberResetVO) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/api/member/reset`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: {
      userId: resetFormData.userId,
      taxId: resetFormData.taxId,
      accnNo: resetFormData.accnNo,
      cfrmUserId: resetFormData.cfrmUserId,
    },
  };
  const response: CommonResponse = await callJwtApi(request);
  return response;
};

// 마이페이지 > 회원정보 수정
export const updateMemberInfoApi = async (signUpFormData) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/api/member`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    bodyParams: signUpFormData,
  };
  const response: CommonResponse = await callJwtApi(request);
  return response.successOrNot === 'Y' ? true : false;
};

// 회원가입시 인증서 유효성 체크
export const validSignUpJointCertificate = async (searchParams: LoginInfo) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/valid-joint-certificate`,
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ ...searchParams }),
  };
  const response: CommonResponse<boolean> = await callJwtApi(request);
  return response.successOrNot === 'Y' ? (response.data as boolean) : null;
};

export const getVdcpCdMap = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `${PREFIX_URI}/findVdcpCdMap`, // 사용자 ID를 query string으로 전달
    serviceName: ServiceName.YOUR_BACK_END_SERVICE_NAME,
    queryParams: new URLSearchParams({ userId }), // URLSearchParams로 쿼리 파라미터 설정
  };

  const response: CommonResponse<MemberVdcpCdMapVO> = await callApi(request);

  return response.successOrNot === 'Y' ? (response.data as MemberVdcpCdMapVO) : null;
};
